@use "../scss/variables.module.scss";

.faq-tab {
  // background: variables.$secondaryGrey;
  padding: 48px 16px;
  width: 100%;
  margin: 0 auto;
  max-width: 1216px;
  box-sizing: content-box;
  @media (max-width: 768px) {
    padding: 30px 16px 64px;
    box-sizing: border-box;
  }

  h3 {
    margin-top: 0;
    border-bottom: 1px solid variables.$headingBorder;
    padding-bottom: 16px;
    margin-bottom: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: variables.$secondaryDark;
  }
}

.minus,
.plus {
  font-size: 20px !important;
}

.minus {
  display: none !important;
}

.Mui-expanded {
  .minus {
    display: inline-block !important;
  }

  .plus {
    display: none !important;
  }
}

.panel-content {
  padding-right: 24px !important;

  .answer-label {
    font-weight: 700 !important;
  }
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 0 16px !important;
  border-radius: 16px !important;
}

.faq-tab .MuiCollapse-entered {
  margin-top: -24px;
  position: relative;
  z-index: 100;
  transition: margin-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}