@media screen and (max-width:369px) {
    .impact_tab_carousel {
        margin-bottom: 40px;
        .carousel-slider {
            height:280px !important; 
        }
        .slider-wrapper {
            height: 260px !important;
        }
        .carousel .control-dots .dot.selected {
            background-color:#e7424b;;
        }
    }
}

@media screen and (min-width: 370px) {
    .impact_tab_carousel {
        margin-bottom: 48px;
        .carousel-slider {
            height:265px !important; 
        }
        .slider-wrapper {
            height: 260px !important;
        }
        .carousel .control-dots .dot.selected {
            background-color:#e7424b;;
        }
    }
}

@media screen and (min-width: 767px) {
    .impact_tab_carousel {
        margin-bottom: 48px;
        .carousel-slider {
            height:235px !important;
        }
        .slider-wrapper {
            height: 260px !important;
        }
        .carousel .control-dots .dot.selected {
            background-color:#e7424b;;
        }
    }
}

@media screen and (min-width: 370px) {
    .impact_giveindia_tab_carousel {
        margin-bottom: 48px;
        .carousel-slider {
            height:145px !important;
        }
        .slider-wrapper {
            height: 200px !important;
        }
        .carousel .control-dots .dot.selected {
            background-color:#e7424b;;
        }
    }
}

.MuiMenu-paper {
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.10);
}