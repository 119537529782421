@use "../../../assets/scss/variables.module.scss";

.homepage_carousel .slick-slider {
    width: 100%;
}

.placeHolderImg {
    // background: url('../../../assets/images/spinner.gif') no-repeat;
    width: 100%;
    background-position: center center;
    background-color: variables.$footerBgColor;
}

.homepage_carousel .pointer {
    cursor: pointer;
}

.btn-slider {
    width: max-content;
    height: auto;
}

.w_100 {
    max-width: 100% !important;
}
