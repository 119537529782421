@use "../scss/variables.module.scss";

.approve {
  color: rgb(0, 140, 140);
  font-size: 36px;
  display: inline-block;
  margin-bottom: 30px;
}
.alert {
  color: rgb(255, 80, 80);
  font-size: 36px;
  display: inline-block;
  // margin-bottom: 30px;
}

.login {
  .close-login-button {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  h5 {
    font-weight: 400;
    margin-bottom: 16px;
  }

  .light {
    color: #9b9b9b;
    font-size: 14px;
    margin-bottom: 16px;
  }
}

.home-search-dialog {
  h2 {
    margin: 0 0 16px;
    color: variables.$primary;
    font-size: 18px;
    font-weight: 700;
  }

  .input-search {
    margin-bottom: 28px;
  }

  .search-filters {
    margin-bottom: 28px;
  }

  .campaign-list {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }

  .see-all-results {
    text-align: center;
  }
}
