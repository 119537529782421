@use "../../../../src/assets/scss/variables.module.scss";

.hundered_heroes {
  background: linear-gradient(270deg, #222 56.09%, #666 100%), #333;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    height: 42px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.hundered_heroes_text {
  color: #FFF;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  @media only screen and (max-width: 767px) {
    font-size: .875rem;
    line-height: 22px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 20px;
  }
}

.header_wrapper {
  width: 100%;
  // -webkit-backdrop-filter: blur(20px);
  // backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 99;
  /* To hide the freshchat icon behind the nav wrapper on mobile */
  // z-index: 2147483601; /* To hide the freshchat icon behind the nav wrapper on mobile */

}

.header_innerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  padding: 18px 16px;

  @media only screen and (max-width: 767px) {
    padding: 16px;
    box-sizing: border-box;
  }
}

.h_leftwrapper {
  img {
    height: 47px;

    @media only screen and (max-width: 767px) {
      height: 44px;
    }
  }
}

.h_rightwrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      margin: 0 20px;
      padding: 12px 0;
      color: #000;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;

      svg {
        fill: #999;
        margin-left: 3px;
      }

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .h_dropdown {
    position: absolute;
    left: 0;
    top: 48px;
    background: variables.$white;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;

    &:after {
      content: "";
      /* Required to display content */
      position: absolute;
      /* Sets the position absolute to the top div */
      top: 0;
      left: 40px;
      /* position the little arrow */
      margin-left: -10px;
      margin-top: -11px;
      /* Set margin equal to border px */
      width: 0;
      z-index: 99;
      height: 0;
      border-bottom: solid 12px #fff;
      /* Creates the arrow pointing up, to change to a notch instead user border-top */
      border-left: solid 10px transparent;
      /* Creates triangle effect */
      border-right: solid 10px transparent;
      /* Creates triangle effect */
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      width: 240px;

      li {
        width: 100%;
        padding: 16px;
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        color: #666;

        &:hover {
          background: rgba(253, 242, 226, 0.5);
          color: #222;
        }
      }
    }
  }

  // Profile dropdown Stylings
  .p_dropdown {
    right: 0;
    left: auto;
    top: 50px;

    &:after {
      margin-left: 26px;
    }

    ul {
      width: 115px;
    }
  }

  .h_btnwrapper {
    display: flex;
    align-items: center;

    button {
      &:first-child {
        margin: 0 8px 0 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.user_loggedIn {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  
  object-fit: cover;
  object-position: center;

  img {
    height: 48px;
    max-width: 48px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1;    
    border: 2px solid;
    padding: 1px;
    box-sizing: border-box;
  }
}

// .user-profile-wrap {
//   width: 100%;
//   max-width: 44px;
//   height: 44px;
//   border-radius: 50%;
//   object-fit: cover;
//   object-position: center;
// }

.mob_user_loggedIn {
  height: 44px;
  width: 44px;
  position: relative;
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }

  img {
    // border: 3px solid #999999;
    max-width: 41px;
    height: 41px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1;    
    border: 2px solid;
    padding: 1px;
    box-sizing: border-box;
  }

  .overlap_hamburger {
    background: #999999;
    border-radius: 40px;
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;
      fill: #fff;
    }
  }
}

.mobileNavWrapper {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  width: 100%;
  height: 100dvh;
  left: 0px;
  top: 0px;
  z-index: 100;
  // z-index: 9999999999;
  transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.hamburger {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;

    svg {
      width: 40px;
      height: 40px;
      fill: variables.$secondaryDark;
    }
  }
}

.mobileNav {
  width: 85%;
  height: 100dvh;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 100;
  background: variables.$white;
  padding: 72px 24px 16px;
  box-sizing: border-box;
  animation: leftSlide 0.2s ease 0s 1 normal forwards;

  @keyframes leftSlide {
    0% {
      opacity: 0;
      transform: translateX(50px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.closebtn {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 16px;
  top: 16px;
  background: variables.$textFocus;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
}

.navList {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      width: 100%;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      color: #1f2a55;

      .navItem {
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .directItem {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 16px 0;
          margin: -16px 0;
        }
      }

      &.active {
        background: #fef8f0;
        border-radius: 8px;

        .navItem {
          background: #fbe0be;
          border-radius: 4px;
        }

        svg {
          rotate: 180deg;
        }
      }

      svg {
        width: 24px;
        height: 24px;
        fill: #999;
      }

      ul {
        width: 100%;
        animation: verTop 1s ease 0s 1 normal forwards;

        a {
          text-transform: none;
          outline: 0;
        }

        li {
          font-weight: 500;
          padding: 12px 24px;
          width: 100%;
          box-sizing: border-box;

          &:focus-visible {
            outline: 0;
          }
        }
      }
    }
  }
}

.navCtas {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  background: variables.$white;
  border-top: 1px solid #e8e8e8;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  button {
    &:first-child {
      margin: 0 0 16px;
    }
  }
}

// Login Button

.leftLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .left-icon-dropdown {
    padding: 0 10px;
    color: variables.$tertiaryDark;
  }

  .left-icon-search {
    font-size: 20px;
    padding: 0 15px;
    color: variables.$tertiaryDark;
  }
}

.popper {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
  border-radius: 3px;
  padding: 8px;
  width: 100%;
  max-width: 150px;
  margin-top: 5px;
  border: 1px solid #9b9b9b;
  background: white !important;
}

.navLink {
  text-decoration: none;
  cursor: pointer;
}

.fundraiser_header_wrapper {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
}

.heroes_header_wrapper {
  background: #F8F1F1;
  padding: 16px 0px;
  text-align: center;
  cursor: pointer;
}

.heroes_header_black {
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 22px;
  }
}

.heroes_header_red {
  color: #E7424B;
}

.stickyPartnerHeader{
  z-index: 99;
  background: variables.$white !important;
}