@use "../scss/variables.module.scss";

.thankyou {
  text-align: center;
  margin: 0 15px;
  // margin-bottom: 40px;
  .background {
    margin-top: 30px;
    img {
      position: absolute;
      top: -60px;
      left: 0;
      width: 100%;
      z-index: -10;
    }
  }
  .top-image-two {
    display: none;
  }
  .top-image {
    display: block;
    width: 250px;
    cursor: pointer;
    max-width: 138px;
  }
  .thankyou-heading {
    margin-bottom: 60px;

    .close-button {
      z-index: 30;
    }
  }
  .logo {
    width: 100%;
  }

  h2 {
    font-size: 34px;
    font-weight: 400;
  }

  h3 {
    font-size: 28px;
    font-weight: bold;
  }

  p {
    font-size: 18px;
    color: #000000;
  }

  .card-section {
    // max-width: 700px;
    max-width: 900px;
    width: 100%;
    margin: auto;

    .amounts {
      display: flex;
      justify-content: space-around;
      h2 {
        margin: 0;
        font-size: 38px;
        color: variables.$primary;
        font-weight: 700;
      }
    }
    .cards {
      display: flex;
      justify-content: space-around;
      .single-card {
        width: 100%;
      }
      .card-head {        
        width: 100%;
        // height: 115px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        position: relative;
        color: #fff;
        .card-head-content {
          padding: 16px 10px;
          position: relative;
          text-align: left;
          h5 {
            color: #fff;
            // margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 1.5em;
          }

          .single-line {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 1.5em;
          }
          p {
            color: #fff;
            font-size: 16px;
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
        }
      }

      .funds-raised {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 16px;
        h5 {
          color: variables.$primary;
        }
        p {
          color: #666666;
          font-weight: 700;
        }
        small {
          font-size: 13px;
          color: #444444;
        }
      }
      .donations {
        text-align: left;
        p {
          font-size: 16px;
          font-weight: 700;
          color: #666666;
          span {
            font-size: 13px;
            color: #444444;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.thankyou-header {
  margin: 40px 0px 10px 0px;
  font-weight: bold !important;
  font-family: variables.$secondaryFont;
  font-size: 30px !important;
}
.MuiOutlinedInput-adornedStart {
  padding-left: 10px !important;
}
.MuiInputAdornment-positionStart {
  margin-right: 10px !important;
}
@media only screen and (max-width: 768px) {
  .thankyou {
    text-align: center;
    margin: 0 20px;
    margin-bottom: 40px;
    .background {
      margin-top: 60px;
      img {
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        z-index: -10;
        object-fit: none;
      }
    }
    .top-image-two {
      display: block;
      margin: 0 auto;
      width: 160px;
      cursor: pointer;
      max-width: 110px;
    }
    .top-image {
      display: none;
    }
    .card-section {
      // width: 300px;
      .cards {
        display: block;
        .single-card {
          width: 100%;
          max-width: 476px;
        }
        .card-center {
          margin: 10px auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .thankyou {
    .card-section {
      // max-width: 700px;
      max-width: 900px;
      .cards {
        .single-card {
          max-width: 300px;
        }
      }
    }
  }
  .card-single {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 767px) {
  .mb-md-66 {
    margin-bottom: 66px;
  }
  .card-section {
    h3 {
      margin-bottom: 45px;
    }
  }
  .mb-md-75 {
    margin-bottom: 75px;
  }
}
