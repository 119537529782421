@use '../../assets/scss/variables.module.scss';

.benefits {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  p {
    margin: 10px 10px;
    color: #444444;
  }
}
.payment {
  max-width: 520px;
  width: 100%;
}

.payment-methods {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 4px;
  background-color: #f6f6f6;
  padding: 10px 30px 0;
  margin-bottom: 30px;
  .pm-item{
    margin: 0 10px 10px;
    display: flex;
    align-items: center;
    img{
      height: 20px;
    }
    span{
      margin: 0 0 0 10px;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .payment-methods{
    padding: 10px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .benefits {
    margin-bottom: 30px;
  }
  .payment-methods{
    margin: 0 10px 30px;
  }
}

@media only screen and (max-width: 370px) {
  .benefits {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    p {
      margin: 10px 10px;
      color: variables.$secondary;
    }
  }
}
