@use "../scss/variables.module.scss";

.program-card {
  .card-header {
    height: 125px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    position: relative;
    padding: 0 19px 10px;
    display: flex;
    align-items: flex-end;
    .urgent-tag {
      position: absolute;
      top: 10px;
      right: 0;
      background: variables.$primary;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: 26px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      p {
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #ffffff;
      }
    }
  }

  .card-body {
    .program-name {
      padding: 10px 16px;
      border-bottom: 1px solid #f2f2f2;
      box-sizing: border-box;
      min-height: 95px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h5 {
        font-size: 18px;
        color: #212121;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        span {
          color: #666666;
          font-size: 13px;
          margin-right: 4px;
        }
      }
    }

    .program-stats {
      display: flex;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fcfcfc;
      .single-detail {
        width: 50%;
        padding: 10px 16px;
        text-align: center;
        p {
          font-size: 11px;
          color: #999999;
          margin-bottom: 8px;
        }
        h6 {
          font-size: 15px;
          color: #444444;
        }
      }
      .border-right {
        border-right: 1px solid #f2f2f2;
      }
    }

    .active-fundraisers {
      background-color: #fcfcfc;
      padding: 10px 16px;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
    }

    .ngo-name {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;

      div {
        width: 50px;
        height: 50px;
        margin-right: 16px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 40px;
        }
      }
      p {
        font-size: 14px;
        color: #444444;
        font-weight: 700;
      }
    }

    .card-action {
      padding: 16px;
      display: flex;
      gap: 16px;
    }
  }
}

.nonprofit-card {
  .card-header {
    height: 125px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    position: relative;
    padding: 0 19px 10px;
    display: flex;
    align-items: flex-end;
  }

  .card-body {
    .nonprofit-name {
      padding: 10px 16px;
      border-bottom: 1px solid #f2f2f2;
      box-sizing: border-box;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h5 {
        font-size: 18px;
        color: #212121;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        span {
          color: #666666;
          font-size: 13px;
          margin-right: 4px;
        }
      }
    }

    .nonprofit-stats {
      display: flex;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fcfcfc;
      .single-detail {
        width: 50%;
        padding: 10px 16px;
        text-align: center;
        p {
          font-size: 11px;
          color: #999999;
          margin-bottom: 8px;
        }
        h6 {
          font-size: 15px;
          color: #444444;
        }
      }
      .border-right {
        border-right: 1px solid #f2f2f2;
      }
    }

    .nonprofit-location {
      padding: 10px 16px;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
      p {
        font-size: 14px;
        color: #444444;
        font-weight: 700;
      }
    }
    .card-action {
      padding: 16px;
      display: flex;
      gap: 16px;
    }
  }
}
