@use "../scss/variables.module.scss";

.navbar {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 100;

  .search-container {
    .input {
      width: 80%;
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;

    // justify-content: center;
    .logo {
      width: 100%;
      height: 60px;
    }
  }

  .mobile-logo {
    width: 100%;
    height: 40px;
  }
}

.mobile-links {
  .profile-link {
    font-size: 20px;
  }
}

.profile-link {
  font-size: 18px;
  color: variables.$secondary;
  text-decoration: none;

  &:hover {
    color: #00a0c6;
    text-decoration: none;
    cursor: pointer;
  }
}

.leftLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .left-icon-dropdown {
    padding: 0 10px;
    color: #666666;
  }

  .left-icon-search {
    font-size: 20px;
    padding: 0 15px;
    color: #666666;
  }
}

.user-coins {
  background-color: #FEF8F0;
  padding: 8px 12px;
  border-radius: 100px;
  margin-right: 12px;
  img {
    max-width: 22px;
    height: 22px;
    padding-right: 4px;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #D97613;
  }
}
.user-profile-wrap {
  width: 100%;
  max-width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;

  img { 
    max-width: 44px;
    border-radius: 50%;
    height: 44px;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1;    
    border: 2px solid;
    padding: 1px;
    box-sizing: border-box;
  }
}

.image {
  width: 100%;
  max-width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

a {
  color: #000000;
  text-decoration: none;
}

.navbar-mobile {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;

  .MuiToolbar-gutters {
    padding-left: 10px;
    padding-right: 10px;
  }

  .fas.fa-search,
  .fas.fa-bars {
    font-size: 17px;
    color: #666666;
  }
}

.create-fundraiser-navbar-options {
  .navbar-options {
    display: flex;
    align-items: center;

    p {
      color: #444444;
      margin: 0;
      font-size: 15px;
      font-weight: 600;
    }

    .active {
      p {
        color: variables.$secondary;
      }
    }

    i {
      font-size: 10px;
      color: #999999;
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 10px 16px;

    .logoContainer {
      .logo {
        max-width: 160px;
        height: auto;
      }
    }
  }

  .mobile-login button {
    padding: 6px 10px;
  }
}