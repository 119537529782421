@use "../scss/variables.module.scss";

.gi-fund-unit {
  .single-fund-amount {
    display: flex;
    align-items: flex-start;
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    .fund-details {
      margin-left: 16px;
      width: 100%;
      .fund-heading {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-bottom: 6px;
      }
      p {
        font-size: 14px;
        color: #666666;
      }
      .counter {
        display: flex;
        align-items: center;
      }
      .amount-increment {
        margin: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5{
          margin-right: 10px;
        }
        .counter {
          display: flex;
          align-items: center;
          .span {
            display: inline-block;
            width: 32px;
            height: 32px;
            color: variables.$primary;
            border: 1px solid #dddddd;
            line-height: 28px;
            text-align: center;
            font-size: 22px;
            padding: 0;
          }
          .input-field {
            font-size: 16px !important;
            &::placeholder {
              color: variables.$primary;
            }
          }

          .decrement-button {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
          }

          .increment-button {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }
  }
}

.amount-unit-popup {
  margin: 0px 10px;
}

@media only screen and (max-width: 600px) {
  .gi-fund-unit {
    .single-fund-amount {
      .fund-details {
        margin-left: 10px;
        h5{
          font-size: 18px;
        }
      }
    }
  }
}
