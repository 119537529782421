@use "../scss/variables.module.scss";

.social-links {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    color: variables.$secondary;
    font-weight: 600;
  }
  .links {
    .link-button {
      margin-right: 10px;
      height: 20px;
      display: none;
    }
    i {
      font-size: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
    .fa-facebook-square {
      color: rgb(92, 124, 250);
    }
    .fa-twitter-square {
      color: #22b8cf;
    }
    .fa-whatsapp-square {
      color: #25d366;
    }
    .fa-linkedin {
      color: #0e76a8;
    }
    .fa-envelope-square {
      color: blue;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .social-links {
    display: block;
  }
  .links {
    .link-button {
      margin-right: 0px;
    }
  }
}
