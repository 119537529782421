h1 {
  font-size: 28px;
}

h2 {
  font-size: 26px;
}

h4 {
  font-size: 22px;
  margin: 0;
}

h5 {
  font-size: 20px;
  margin: 0;
}

h6 {
  font-size: 18px;
  margin: 0;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-transform-none {
  text-transform: none !important;
}


.text-decoration-none {
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.d-flex.flex-direction-column {
  flex-direction: column;
}

.d-flex.align-items-center {
  align-items: center;
}

.d-flex.justify-content-space-between {
  justify-content: space-between;
}

.d-flex.justify-content-center {
  justify-content: center;
}

.d-flex.justify-content-end {
  justify-content: flex-end;
}

.ml-2 {
  margin-left: 10px;
}

.d-flex.justify-content-space-evenly {
  justify-content: space-evenly;
}

.d-flex.justify-content-space-around {
  justify-content: space-around;
}

.ml-3 {
  margin-left: 16px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 10px !important;
}

.mr-3 {
  margin-right: 16px !important;
}

.mr-3 {
  margin-right: 16px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 18px;
}

.mt-5 {
  margin-top: 30px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 30px !important;
}

.non-editable {
  opacity: 0.35;
}

.cursor-pointer {
  cursor: pointer;
}

.a-tag {
  text-decoration: none;
  color: #444;
}

.sticky-bar {
  position: sticky;
  top: 0;
  z-index: 1001;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pl-2 {
  padding-left: 10px;
}

.pr-2 {
  padding-right: 10px;
}

.pl-3 {
  padding-left: 16px;
}

.pr-1 {
  padding-right: 4px;
}

.inline-block {
  display: inline-block;
}

.pt-2 {
  padding-top: 10px;
}

.on-mobile {
  display: none;
}

.project-heading {
  color: #444;
}

@media only screen and (max-width: 767px) {
  .on-desktop-tablet {
    display: none;
  }

  .on-mobile {
    display: inline-block;
  }
}

.w100 {
  width: 100% !important;
}

.primary-btn-width {
  width: 200px;
}

.primary-btn-height {
  height: 48px;
}

.ml20 {
  margin-left: 20px;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  max-height: 175px;
}

.react-horizontal-scrolling-menu--item {

  margin: 5px;
}

.gradientTitle_1 {
  background: linear-gradient(180deg, #FFD646 0%, #CBA008 100%),
}

.gradientTitle_2 {
  background: linear-gradient(180deg, #C3BDBD 0%, #ABA8A8 100%);

}

.gradientTitle_3 {
  background: linear-gradient(180deg, #FBAF17 0%, #D97613 100%),

}

.carousel-arrow {
  color: #667085;
  font-size: 15px;
  opacity: 0.5;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ml-12 {
  margin-left: 12px !important;
}

.w-200 {
  width: 200px !important;
}


.w100 {
  width: 100%;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  max-height: 175px;
}

.react-horizontal-scrolling-menu--item {

  margin: 5px;
}

.gradientTitle_1 {
  background: linear-gradient(180deg, #FFD646 0%, #CBA008 100%),
}

.gradientTitle_2 {
  background: linear-gradient(180deg, #C3BDBD 0%, #ABA8A8 100%);

}

.gradientTitle_3 {
  background: linear-gradient(180deg, #FBAF17 0%, #D97613 100%),
  
}

.carousel-arrow {
  color: #667085;
  font-size: 15px;
  opacity: 0.5;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ml-12{
  margin-left: 12px !important;
}
.w-200{
  width: 200px !important;
}
.pt-0{
  padding-top: 0 !important;
}