@use "../variables.module.scss";

.complete-pending-tasks-section {
  .back-button-container {
    border-bottom: 1px solid #efefef;
    padding: 24px 0;
    .back-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      padding: 4px 0;
      background-color: #efefef;
      border-radius: 5px;
      cursor: pointer;
      i {
        font-size: 10px;
        color: #757575;
        margin-right: 4px;
      }

      p {
        font-size: 14px;
        color: #757575;
      }
    }
  }

  .complete-tasks-body {
    background-color: #fcfcfc;
    padding-top: 50px;
    h3 {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
      margin: 0 0 24px;
    }

    .complete-details-card {
      min-height: 100vh;
      .card-header {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #efefef;
        h5 {
          margin: 0;
          font-size: 13px;
          font-weight: 600;
        }
      }

      .card-body {
        padding: 47px 56px;
        border-bottom: 1px solid #efefef;

        .note {
          margin-bottom: 32px;
        }
        form {
          .gap-1 {
            gap: 16px;
          }
          .w-50 {
            width: 50%;
          }
          .form-control {
            margin-bottom: 24px;

            label {
              font-size: 15px;
              color: rgba(0, 0, 0, 0.66);
              display: block;
              font-weight: 600;
              margin-bottom: 12px;
            }

            input,
            select {
              background: #fff;
              width: 100%;
              box-sizing: border-box;
              border: 1px solid #ced4da;
              font-size: 16px;
              height: 48px;
              border-radius: 5px;
              padding: 0 16px;
              font-family: variables.$primaryFont;
              &:focus {
                outline: 0;
              }

              &::placeholder {
                color: rgba(0, 0, 0, 0.33);
              }
            }
          }
          .form-control.checkbox {
            label {
              display: inline;
            }
            input {
              width: auto;
              height: auto;
              margin: 0 8px 0 0;
            }
          }

          .add-reports {
            p {
              font-size: 15px;
              color: rgba(0, 0, 0, 0.66);
              font-weight: 600;
              margin-bottom: 8px;
            }

            .add-reports-body {
              flex-wrap: wrap;
            }

            .diagnostic-report {
              border: 1px solid #ced4da;
              height: 48px;
              display: flex;
              align-items: center;
              border-radius: 5px;
              padding: 0 16px;
              max-width: 260px;
              i {
                margin-right: 8px;
              }
              p {
                margin: 0;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.66);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              span {
                margin-left: 8px;
                font-weight: 600;
                font-size: 18px;
              }
            }
          }

          .upload-file {
            margin-bottom: 24px;
            p {
              font-size: 15px;
              color: rgba(0, 0, 0, 0.66);
              font-weight: 600;
              margin-bottom: 12px;
            }

            .upload-input {
              position: relative;

              button {
                position: absolute;
                font-size: 14px;
                height: 32px;
                width: 80px;
                border-radius: 5px;
                color: #ffffff;
                background: variables.$secondary;
                border: 0;
                top: 8px;
                left: 10px;
                cursor: pointer;
              }

              // .document-area {
              //   height: 48px;
              //   padding: 0 16px 0 100px;
              //   font-size: 16px;
              //   display: flex;
              //   align-items: center;
              //   p {
              //     margin: 0;
              //   }
              // }

              // .document-present {
              //   padding: 6px 12px;
              //   border: 1px solid #ced4da;
              //   border-radius: 25px;
              //   font-size: 14px;

              //   i {
              //     margin-right: 4px;
              //   }
              // }

              input {
                background: #fff;
                width: 100%;
                box-sizing: border-box;
                font-size: 16px;
                height: 48px;
                padding: 0 16px 0 100px;
                font-family: variables.$primaryFont;
                border: 1px solid #ced4da;
                border-radius: 5px;

                &:focus {
                  outline: 0;
                }
              }
            }
          }
        }
      }

      .card-footer {
        padding: 24px 56px;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p {
          margin-right: 16px;
          cursor: pointer;
        }
      }
    }

    .add-images-to-fundraiser-card {
      .card-body {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        .add-image-card {
          width: 142px;
          height: 142px;
          background-color: #f2f2f2;
          border-radius: 8px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img {
            margin-bottom: 12px;
          }
          p {
            color: rgba(102, 102, 102, 0.87);
            font-size: 12px;
          }
        }

        .fundraiser-image {
          width: 142px;
          height: 142px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          position: relative;

          i {
            position: absolute;
            color: variables.$primary;
            top: -12px;
            right: -12px;
            font-size: 22px;
            background: white;
            border-radius: 50%;
            cursor: pointer;
          }
          img {
            width: 142px;
            height: 142px;
            border-radius: 8px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.pending-pop-up {
  background-color: rgb(77, 158, 219);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  // height: 64px;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-container {
    display: flex;
    align-items: center;
    i {
      color: #fff;
      font-size: 13px;
    }
    p {
      margin-left: 12px;
      font-size: 15px;
      color: #fff;
      font-weight: 500;
      span {
        font-weight: 700;
      }
    }
  }

  .complete-task-btn {
    width: 220px;
    height: 48px;
    background: #ffffff;
    font-size: 14px;
    text-transform: none;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}
@media only screen and (max-width: 1023px) {
  .complete-pending-tasks-section{
    .back-button-container{
      h5{
        margin: 0 10px 0 0;
        max-width: 80%;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .complete-pending-tasks-section {
    .back-button-container {
      padding: 16px;

      h5 {
        font-size: 15px;
      }
      .back-button {
        padding: 4px;
        p {
          font-size: 11px;
        }
      }
    }
    .complete-tasks-body {
      padding-top: 24px;
      .complete-details-card {
        .card-header {
        }
        .card-body {
          padding: 47px 16px;
          form {
            .form-control {
              label {
                font-size: 14px;
              }
            }
          }
        }

        .card-footer {
          flex-direction: column;
          justify-content: center;
          p {
            margin: 0 0 16px 0;
          }
        }
      }
    }
  }
  .pending-pop-up {
    flex-direction: column;
    margin: 0 16px;

    .icon-container {
      margin-bottom: 8px;
    }
    .btn-container {
      width: 100%;
      .complete-task-btn {
        width: 100%;
      }
    }
  }
}
