.gi-fund-reward {
  .single-reward-fund {
    margin-bottom: 11px;
    ul {
      padding-left: 16px;
      color: #666666;
    }
    p {
      margin-bottom: 10px;
    }

    h6 {
      margin-bottom: 10px;
    }

    .fund-description {
      // white-space: nowrap !important;
      // overflow: hidden !important;
      // text-overflow: ellipsis !important;

      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
    }
  }  

  .fund-details {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;

    .rimg-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      max-height: 90px;
      overflow: hidden;
      margin-right: 15px;
      img {
        width: 100%;
      }
    }
    

    .fund-price {      

      p {
        color: #666666;
        font-size: 14px;
      }
    }
  }
}
