@use "../scss/variables.module.scss";

.gi-fund-donors {
  display: flex;
  margin-bottom: 16px;

  .donor-details {
    margin-left: 16px;
    p {
      margin: 0;
      font-size: 16px;
      color: #444;
      span {
        font-weight: 700;
      }
    }
    .small {
      font-size: 14px;
      color: #666;
    }
  }
}

.avatar {
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.view-all {
  margin-left: 46px;
  font-weight: 700;
  color: variables.$secondary
}
.no-donoations {
  text-align: center;
  p {
    font-size: 16px;
    color: #444;
  }
}

.mobile-donors {
  h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44;
    color: #161616;
    margin-bottom: 6px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #161616;
    line-height: 1.3;
    margin-bottom: 38px;
  }
}
