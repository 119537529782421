@use "../variables.module.scss";

.create-fundraiser-login {
  .login-card-container {
    max-width: 702px;
    width: 100%;
    margin: 32px auto;

    .login-card {
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
      max-width: 450px;
      width: 100%;
      margin: auto;
      min-height: 512px;
      border-radius: 5px;

      .back-button {
        // margin: 16px 0 16px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 59px;
        height: 28px;
        background-color: #efefef;
        border-radius: 5px;
        cursor: pointer;
        i {
          font-size: 10px;
          color: #757575;
          margin-right: 4px;
        }

        p {
          font-size: 14px;
          color: #757575;
        }
      }

      .login-hero {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        color: #ffffff;
        background-color: variables.$primary;
        width: 40%;
        padding: 66px 48px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .login-hero-header {
          h2 {
            font-size: 28px;
            margin: 0 0 24px;
          }
          p {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.76);
            font-weight: 600;
            margin: 0;
          }
        }
        .login-hero-footer {
          p {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.76);
            span {
              text-decoration: underline;
            }
          }
        }
      }
      .login-action {
        padding: 66px 48px;

        .login-details-entry {
          margin-bottom: 32px;

          .login-social {
            margin-bottom: 16px;
            button {
              background-color: white;
              border: 1px solid #ced4da;
              border-radius: 5px;
              width: 100%;
              height: 48px;
              position: relative;
              font-size: 15px;
              color: #212121;
              font-weight: 500;

              i {
                position: absolute;
                left: 5%;
                font-size: 20px;
                font-weight: 500;
              }
              i.fa-facebook {
                color: #3b5998;
              }
            }
          }

          .login-or {
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .border {
              width: 45%;
              border-bottom: 1px solid #efefef;
            }
            p {
              color: #999999;
              font-size: 13px;
              margin: 0 8px 0;
              font-weight: 700;
            }
          }

          .login-form {
            .form-control {
              margin-bottom: 16px;
              label {
                color: #757575;
                font-weight: 700;
                font-size: 14px;
                display: block;
                margin-bottom: 8px;
              }

              input {
                border: 1px solid #ced4da;
                background-color: #ffffff;
                color: #212121;
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                padding: 0 16px;
                border-radius: 4px;
              }
            }
          }
        }

        .login-otp-verification {
          text-align: center;
          margin-bottom: 32px;

          .otp-verification-header {
            margin-bottom: 24px;

            h3 {
              font-size: 17px;
              color: #212121;
              margin: 0 0 24px;
            }

            p {
              color: #757575;
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 8px;
            }
            .login-credentials {
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                color: rgba(0, 0, 0, 0.87);
                margin: 0 8px 0 0;
              }
            }
          }

          .otp-verification-enter {
            .otp-input {
              margin-bottom: 24px;
            }
            p {
              font-size: 14px;
              font-weight: 700;
              color: #757575;

              span {
                color: variables.$secondary
              }
            }
          }
        }
        .login-footer {
          text-align: center;
          p {
            font-size: 14px;
            color: #999999;
            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .create-fundraiser-login-footer {
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 600;
      color: #444444;
      span {
        color: variables.$primary;
      }
    }
  }
}
