@use '../scss/variables.module.scss';

.report {
  border-radius: 4px;
  justify-content: center;
  text-align: center;
  background-color: #e6e9f2;
  padding: 25px;

  .campaign-name {
    color: variables.$secondary;
  }
  .button {
    margin: 0px 10px;
  }
}
