@use "../scss/variables.module.scss";

.tab-div {
  p {
    font-size: 16px;
    color: variables.$black;
    font-weight: normal;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    color: variables.$black;
  }
  h5 {
    font-size: 21px;
    font-weight: 600;
  }
  .about {
    display: flex;
    i {
      margin-top: 20px;
      margin-right: 20px;
      font-size: 20px;
    }
    .width {
      width: 100%;
    }
  }

  .campaign-description {
    // border-bottom: 1px solid #979797;
    padding-bottom: 24px;
    h1,
    h2,
    h3 {
      margin: 0 0 15px;
    }

    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      margin: 0 0 15px;
    }
    img,
    video {
      max-width: 100%;
      margin: 0 0 15px;
    }
    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      color: blue;
      text-decoration: underline;
    }
    iframe {
      width: 100%;
      height: 340px;
      margin: 0 0 15px;
    }
    ul,
    ol {
      margin: 0 0 15px;
      padding: 0 0 0 15px;
    }
    ul li {
      list-style: disc;
      margin: 0 0 5px;
    }
    ol li {
      list-style: decimal;
      margin: 0 0 5px;
    }
    p ul,
    p ol {
      margin: 10px 0 0;
    }
    blockquote {
      border-left: 6px solid variables.$lightGrey;
      margin: 20px 10px 20px 0;
      padding: 8px 10px;
      quotes: "\201C""\201D""\2018""\2019";
      font-style: italic;
    }
    blockquote:before {
      color: variables.$lightGrey;
      content: open-quote;
      font-size: 3em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    blockquote:after {
      color: variables.$lightGrey;
      content: close-quote;
      font-size: 3em;
      line-height: 0.1em;
      margin-left: 0.1em;
      vertical-align: -0.6em;
    }
    .campaign-msg blockquote p {
      display: inline;
    }
  }

  .ngo-details {
    display: flex;
    align-items: center;
    img {
      width: 47px;
      margin-right: 10px;
      object-fit: cover;
    }
  }
  a {
    text-decoration: none;
    img {
      width: 100%;
    }
    iframe {
      width: 100%;
      height: 300px;
    }
  }
}

.project-benefits {
  display: flex;
  align-items: center;
  img {
    width: 47px;
    margin-right: 10px;
    object-fit: cover;
  }
  i {
    font-size: 22px;
    margin-top: 5px;
    margin-right: 20px;
  }
  p {
    font-size: 15px;
    font-weight: 500;
    color: variables.$black;
    margin-right: 30px;
    margin-top: 5px;
  }
  span {
    font-size: 20px;
    color: variables.$black;
    font-weight: 600;
  }
}
