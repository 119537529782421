@use "../scss/variables.module.scss";

#global-currency-selector {
  // padding-right: 0;
  cursor: pointer;

  .MuiMenu-list {
    padding: 0;
  }
}

#menu- {
  .MuiMenu-list {
    padding: 0;
  }
}
.fixed_carousel{
  @media only screen and (max-width: 767px) {
        margin-top: 70px !important;  
  }
}
.fundraiser_carousel_panel {
  .custom-carousel {
    // Later check this
    .lazy-load-image-background.blur.lazy-load-image-loaded {
      width: 100%
    }
    span.lazy-load-image-background.blur > img,
    img {
      height: 400px;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 0px !important;

    .custom-carousel {
      span.lazy-load-image-background.blur > img,
      img {
        height: 254px;
        // margin-top: 45px;
        border-radius: unset;
      }
    }
  }

  .control-dots {
    bottom: -40px !important;
    text-align: center !important;

    li {
      position: relative;
      display: table-cell !important;
      cursor: pointer;
    }

    @media only screen and (max-width: 767px) {
      text-align: center !important;
      bottom: -50px !important;
      line-height: 0.5;
      li {
        display: inline-block !important;
        position: relative !important;
      }
    }
  }
}

// .carousel-root.custom-carousel {
//     span.lazy-load-image-background.blur {
//         display: inline !important;
//     }
// }

#edit {
  .fundraiser_carousel_panel {
    margin-top: 0px !important;
  }
}

.updates {
  .fundraiser_carousel_panel {
    @media only screen and (max-width: 767px) {
      margin-top: 20px !important;
    }
  }

  .custom-carousel {
    span.lazy-load-image-background.blur {
      border-radius: 8px;
      height:400px;
      width: 100%;
      display:flex !important;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      @media only screen and (max-width: 767px) {
        height: 254px;
      }
    }
    span.lazy-load-image-background.blur > img,
    img {
      height:auto;
      min-height:100%;
      @media only screen and (max-width: 767px) {
        height: auto;
        min-height:100%;
      }
    }
  }
}

.campaign-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: variables.$secondaryDark;
}

.campaign-story-quote {
  font-size: 12px;
  margin-top: 40px;
  position: relative;
  padding: 5px 0px 0px 20px;
}

.campaign-story-quote:before {
  content: "";
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  background: variables.$primary;
  margin-right: 10px;
}

.campaign-story-description {
  line-height: 28px;
  color: variables.$primaryText;
  font-family: "Libre Baskerville";
  // font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.says-who {
  color: variables.$secondaryText;
  margin-right: 10px;
}

//updateTab.scss
//Rewamp css
.read_more_text {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: variables.$primary;
  margin-bottom: 32px;
  margin-top: 32px;
}

//Story tab scss
//New Rewamp UI css
.campaign_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 38px;
  margin-top: 40px;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.story-react-player {
  width: 100%;
  height: 447px;
  margin-top: 48px;
  margin-bottom: 48px;

  iframe {
    border-radius: 8px !important;
  }

  @media only screen and (max-width: 480px) {
    //mobile
    height: 193px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 768px) {
    //desktop
    height: 447px;
  }
}

.story_line {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: variables.$secondaryDark;
  // padding-top: 24px;

  p {
    margin: 0 0 15px;

    img {
      margin: 0 0 15px;
      width: 100%;
    }
  }

  h2 {
    font-size: 1.25rem;
  }
}

#donor-list-dialog,
.MuiDialog-paperScrollPaper {
  ::-webkit-scrollbar {
    width: 4px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: variables.$scrollBarTrackColor !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: variables.$scrollBarGreyColor !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: variables.$scrollBarGreyColor !important;
  }
}

.carousel .thumbs-wrapper {
  margin: unset !important;
  display: none;
}

.react-player__preview {
  border-radius: 8px;
}
