$primary : #E7424B;
$secondary: #F4A034;
// $grey: #E8E8E8;

$white: #FFFFFF;
$black: #000000;
$primaryGreen: #039654;
$primaryDark: #444444;
$secondaryDark: #222222;
$tertiaryDark: #666666;
$primaryTitleText: #333333;
$iconColor: #999999;
$primaryProgressBar: #FBAF17;
$textBlue:#5925DC;

$primaryText: #101828;
$secondaryText: #667085;

$primaryPink: #EB5757;
$secondaryPink: #eb57571a;

$textFocus: #FAF9F9;
$secondaryOutline: #DEDEDE;
$secondaryGrey: #F9FAFB;

$buttonBorder: #D0D5DD;
$infoTextColor: #4F4F4F;
$buttonFocus: #E9B8B9;
$titleBorderColor: #E8E8E8;
$secondaryTextColor: #656262;

$buttonHoverColor: #F48587;
$buttonFocusColor: #D1585E;
$dividerColor: #E4E7EC;
$secondarySubtitle: #27AE60;
$secondaryBlueWithOpacity: #2f80ed10;
$inputBorderColor: #ced4da;
$currencyBorderColor: #80bdff;
$successColor: #ECFDF3;
$footerBgColor: #F8F1F1;

$scrollBarTrackColor: #f1f1f1;
$scrollBarGreyColor: $titleBorderColor;
$headingBorder :#979797;
$lightGrey: #999999;

$fbIconBgColor: #475993;
$whatsappIconColor: #1bd741;
$tertiaryGrey:#f5f5f5;
$tertiaryWhite :#fcfcfc;
$semiWhite:#dddddd;

$primaryFont: 'Poppins', sans-serif;
$secondaryFont: 'Libre Baskerville', serif;