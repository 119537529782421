@use "../scss/variables.module.scss";

.closed-fundraiser {
  .fundraiser {
    display: flex;
    align-items: center;

    p {
      color: variables.$primaryDark;
      font-size: 16px;
      margin: 0 0 0 16px;
      span {
        font-weight: 700;
        border-bottom: 1px dashed variables.$black;
      }
    }
  }

  .closed-campaign {
    // height: 300px;
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      color: variables.$primaryDark;
      span {
        cursor: pointer;
        color: variables.$secondary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .benefits {
    display: flex;
    justify-content: center;
    p {
      margin: 10px 10px;
      color: variables.$secondary
    }
  }
}
