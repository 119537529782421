@use "../variables.module.scss";

.create-fr-enter-details {
  .sub-container {
    max-width: 1024px;
    margin: auto;
  }
  .create-fundraiser-button {
    padding-bottom: 2em;
  }
  .create-fr-enter-fundraiser-details {
    .banner-container {
      position: relative;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 148px;

      display: flex;
      align-items: center;

      .banner-overlay {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.76) !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .banner-details {
        position: relative;
        h2 {
          margin: 0 0 16px;
          color: #ffffff;
          font-size: 28px;
        }
        p {
          margin: 0;
          span {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.66);
            margin-right: 4px;
          }
        }
      }
    }

    .back-button-container {
      padding: 10px 0;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      margin-bottom: 50px;
      .ngo-details {
        display: flex;
        align-items: center;
        .ngo-card {
          width: 50px;
          height: 50px;
          border-radius: 2px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 40px;
          }
        }

        p {
          font-size: 15px;
          font-weight: 700;
          color: #444444;
        }
      }

      .back-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 59px;
        height: 28px;
        background-color: #efefef;
        border-radius: 5px;
        cursor: pointer;
        i {
          font-size: 10px;
          color: #757575;
          margin-right: 4px;
        }

        p {
          font-size: 14px;
          color: #757575;
        }
      }
    }

    .add-primary-details-container {
      .back-button-mobile {
        display: none;
      }
      .add-primary-details-header {
        h3 {
          color: rgba(0, 0, 0, 0.87);
          font-size: 28px;
          margin: 0 0 16px;
        }
      }

      .add-primary-details-body {
        display: flex;
        gap: 50px;
        .add-primary-details,
        .recommendations {
          width: 50%;
        }

        .add-primary-details {
          .change-details-card {
            background-color: #e7d1d1;
            border-radius: 8px;
            // height: 38px;
            padding: 10px 13px;
            margin-bottom: 42px;
            p {
              color: #444444;
              font-size: 14px;
            }
          }

          form {
            .gap-1 {
              gap: 16px;
            }
            .w-50 {
              width: 50%;
            }
            .form-control {
              margin-bottom: 16px;
              label {
                font-size: 15px;
                color: rgba(0, 0, 0, 0.66);
                display: block;
                font-weight: 700;
                margin-bottom: 8px;
              }

             textarea,select,input {
                width: 100%;
                box-sizing: border-box;
                border: 1px solid #ced4da;
                height: 48px;
                border-radius: 5px;
                padding: 0 16px;

                &:focus {
                  outline: 0;
                }

                &::placeholder {
                  color: rgba(0, 0, 0, 0.33);
                }
              }
            }
            .covid-support {
              display: flex;
              align-items: center;
              label {
                margin: 0 0 0 8px;
              }
              input[type="checkbox"] {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .recommendations {
          .tip-card {
            background-color: #f7f7f7;
            height: 79px;
            padding: 0 24px;
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            p {
              color: #777777;
              font-size: 13px;
              span {
                color: #444444;
                font-weight: 700;
              }
            }
          }
        }
      }

      .quick-tips-container {
        background-color: #f7f7f7;
        padding: 24px;
        .header {
          margin-bottom: 16px;
          h5 {
            font-size: 15px;
            color: #444444;
            font-weight: 700;
            margin-bottom: 8px;
          }

          p {
            font-size: 13px;
            color: #444444;
          }
        }

        .quick-tips {
          display: flex;
          height: 59px;
          background-color: #ffffff;
          margin-bottom: 16px;
          align-items: center;
          justify-content: space-between;
          p {
            padding-left: 16px;
            padding-right: 16px;
            font-size: 13px;
            color: #444444;
            font-weight: 700;
          }
          .select-button {
            background-color: variables.$secondary;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 59px;
            cursor: pointer;
            position: relative;
            overflow: hidden;

            h6 {
              color: #ffffff;
              font-size: 11px;
            }

            &:hover {
              background-color: variables.$secondary;
              transition: 300ms;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .back-button-container {
    .sub-container {
      padding: 0 16px;
    }
  }
  .create-fr-enter-details {
    .sub-container {
      padding: 0 16px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .create-fr-enter-details {
    .create-fr-enter-fundraiser-details {
      .banner-container {
        padding: 0 20px;
        min-height: 104px;
        .banner-details {
          h2 {
            font-size: 19px;
            margin-bottom: 12px;
            line-height: 27px;
          }
          p {
            span {
              font-size: 13px;
            }
          }
        }
      }

      .back-button-container {
        padding: 10px 20px;
        margin-bottom: 28px;
        .ngo-details {
          p {
            font-size: 14px;
            font-weight: 600;
          }
        }
        .back-button {
          display: none;
        }
      }

      .add-primary-details-container {
        .back-button-mobile {
          margin: 0 0 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 59px;
          height: 28px;
          background-color: #efefef;
          border-radius: 5px;
          cursor: pointer;
          i {
            font-size: 10px;
            color: #757575;
            margin-right: 4px;
          }

          p {
            font-size: 14px;
            color: #757575;
          }
        }
        // padding: 0 20px;
        .add-primary-details-header {
          padding: 0;
          h3 {
            font-size: 19px;
          }
        }
        .add-primary-details-body {
          flex-direction: column;
          .add-primary-details,
          .recommendations {
            width: 100%;
          }

          .add-primary-details {
            .change-details-card {
              margin: 0 0 42px;
            }

            form {
              .form-control {
                padding: 0;
              }

              .goal-enddate-container {
                flex-direction: column;
                .form-control {
                  box-sizing: border-box;
                }
              }

              .w-50 {
                width: 100%;
              }

              .create-fundraiser-button {
                padding: 0;
              }
            }
          }

          .recommendations {
            padding: 0;
            box-sizing: border-box;
          }

          .quick-tips-container {
            position: relative;
            .triangle {
              display: inline-block;
              position: absolute;
              top: -10px;
              left: 20px;
            }
            .triangle-4 {
              border-bottom: solid 10px #f7f7f7;
              border-left: solid 7px transparent;
              border-right: solid 7px transparent;
            }
            margin: 20px 0;
            padding: 24px 0;
            .header {
              padding: 0 10px;
            }

            .quick-tips {
              padding: 0 5px;
              margin-left: 10px;
              margin-right: 10px;
              p {
                padding: 0;
              }
              .select-button {
                height: 41px;
              }
            }
          }
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

.error-message {
  color: variables.$primary;
  font-size: 13px;
}

.input-number {
  position: relative;

  span {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
