@use "../scss/variables.module.scss";

.gi-fund-fund {
  margin-bottom: 40px;
  .fundraiser {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    p {
      color: #444444;
      font-size: 16px;
      margin: 0 0 0 16px;
      span {
        font-weight: 700;
      }
    }
  }

  .amount-raised {
    margin-bottom: 13px;
    p {
      color: #666666;
      font-size: 16px;
    }
    .raised-amount-span {
      border-bottom: 1px dashed #000000;
      font-size: 26px;
      font-weight: 700;
      color: #161616;
    }

    .tooltip-text {
      color: #ffffff;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 20px;
      span {
        font-size: 26px;
        font-weight: 700;
        color: #161616;
        border-bottom: 1px dashed #000000;
      }
    }

    .tooltip-change-currency {
      font-weight: 700;
      margin-right: 8px;
      color: #fff;
    }

    .tooltip-select {
      width: 65px;
      height: 25px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 400;
      background: #fff;
    }
  }
  .support {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    p {
      color: #666666;
      span {
        font-size: 20px;
        font-weight: 700;
        color: #161616;
      }
    }
  }

  .benefits {
    display: flex;
    justify-content: center;
    p {
      margin: 10px 10px;
      color: variables.$secondary
    }
  }
}

@media only screen and (max-width: 767px) {
  .gi-fund-fund.mobile-margin {
    margin: 30px 10px;
  }
}

.currency-btn-wrapper {
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  z-index: 1000;
  .currency-dropdown{
    position: absolute;
    left: 0;
    top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 270px;
    background: #ffffff;
    border: 1px solid #666666;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    ul{
      max-height: 150px;
      list-style: none;
      overflow-y: auto;
      margin: 0;
      padding: 0;
    }
    li{
      margin: 0 0 8px;
      text-align: left;
      a{
        text-decoration: none;
        cursor: pointer;
      }
      a:hover{
        color: variables.$primary;
      }
    }
  }
}
.currency-btn {
  background: transparent;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 5px 0 0;
  border: 1px solid #666666;
  border-radius: 4px;
  font-family: variables.$primaryFont;
  color: #444444;
  text-align: center;
}
.currency-symbol{
  display: inline-block;
  margin-right: 5px;
  font-size: 18px;
}
.currency-search-input {
  width: 100%;
  height: 1.5rem;
  border: 1px solid #bdbdbd;
  outline: 0;
  border-radius: 5px;
  padding: 0.25rem;
  margin: 0 0 10px;
  box-sizing: border-box;
}
.not-found-text {
  font-size: 14px !important;
  display: block;
  margin: 0.5rem;
}

.currency-btn.mobile {
  display: none;
}
.currency-popup{
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .blur-screen{
    background: #000;
    opacity: .8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1001;
    overflow: hidden;
  }
  .cdm-close {
    width: 100%;
    text-align: right;
  }
  h4 {
    width: 100%;
    margin: 0 0 5px;
    text-align: center;
    color: #444;
    font-size: 18px;
  }
  .currency-dropdown ul{
    list-style: none;
    max-height: 210px;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    li{
      padding: 6px 5px;
      margin: 0;
      border-bottom: 1px solid #999;
    }
  }
}
.currency-dropdown.mobile{
  position: fixed;
  z-index: 1002;
  top: 30%;
  left: 50%;
  transform: translate(-50%,-30%);
  background-color: #fff;
  width: 250px;
  height: 295px;
  padding: 10px 10px 0;
}

@media only screen and (max-width: 767px) {
  .currency-btn.mobile {
    display: inline-block;
  }
  .currency-btn-wrapper.desktop{
    display: none;
  }
  .currency-popup{
    display: inline-block;
  }
}