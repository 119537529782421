@use "../variables.module.scss";

.create-fundraiser-medical {
  .sub-container {
    max-width: 1024px;
    margin: auto;
  }
  .banner-container {
    height: 132px;
    margin-bottom: 50px;
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;

    display: flex;
    align-items: center;

    .banner-overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.66) !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .banner-details {
      position: relative;

      h2 {
        margin: 0 0 12px;
        color: #ffffff;
        font-size: 28px;
      }
      p {
        margin: 0;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.66);
      }
    }
  }

  .add-patient-details-container {
    display: flex;
    gap: 50px;

    .add-patient-details,
    .medical-faqs {
      width: 50%;
    }

    .add-patient-details {
      .add-patient-details-header {
        margin-bottom: 32px;
        h3 {
          color: rgba(0, 0, 0, 0.87);
          font-size: 19px;
          margin: 0 0 12px;
        }
        p {
          color: rgba(0, 0, 0, 0.54);
          font-size: 14px;
          font-weight: 700;
        }
      }

      .add-patient-details-body {
        form {
          .gap-1 {
            gap: 16px;
          }
          .w-50 {
            width: 50%;
          }
          .form-control {
            margin-bottom: 24px;
            label {
              font-size: 15px;
              color: rgba(0, 0, 0, 0.66);
              display: block;
              font-weight: 600;
              margin-bottom: 12px;
            }

            input,
            select {
              background: #fff;
              width: 100%;
              box-sizing: border-box;
              border: 1px solid #ced4da;
              font-size: 16px;
              height: 48px;
              border-radius: 5px;
              padding: 0 16px;
              font-family: variables.$primaryFont;
              &:focus {
                outline: 0;
              }

              &::placeholder {
                color: rgba(0, 0, 0, 0.33);
              }
            }
          }
        }
      }
    }

    .medical-faqs {
      .faqs-card {
        margin-bottom: 24px;
        .card-header {
          height: 58px;
          display: flex;
          border-bottom: 1px solid #f2f2f2;
          padding: 0 32px;
          align-items: center;
          h3 {
            margin: 0;
          }
        }

        .card-body {
          padding: 32px;
          .faq-question {
            font-size: 15px;
            color: #444444;
            font-weight: 700;
          }

          .faq-answer {
            font-size: 14px;
          }
        }
      }

      .support-card {
        width: 100%;
        height: 77px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 32px;
        box-sizing: border-box;
        p {
          margin: 0;
          font-size: 13px;
          color: #999999;
          span {
            color: #444444;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.success-creation-popup {
  position: relative;
  .close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .success-creation-popup-header {
    margin-bottom: 24px;
    text-align: center;
    img {
      width: 40px;
    }
  }

  .success-creation-popup-body {
    text-align: center;
    margin-bottom: 24px;
    h2 {
      margin: 0 0 16px;
      font-size: 24px;
      color: #212121;
    }
    p {
      font-size: 16px;
      color: #444444;
    }
  }

  .success-creation-popup-footer {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .create-fundraiser-medical {
    .sub-container {
      padding: 0 16px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .create-fundraiser-medical {
    .sub-container {
      padding: 0;
    }
    .banner-container {
      padding: 10px 16px;
      margin-bottom: 28px;
      height: auto;
      h2 {
        font-size: 21px;
        margin-bottom: 8px;
      }
    }

    .add-patient-details-container {
      flex-direction: column;
      .add-patient-details,
      .medical-faqs {
        width: 100%;
      }

      .add-patient-details {
        padding: 0 16px;
        box-sizing: border-box;
        .add-patient-details-body {
          form {
            .goal-enddate-container {
              flex-direction: column;
              gap: 0px;
              .w-50 {
                width: 100%;
              }
            }
          }
        }
      }

      .medical-faqs {
        .support-card {
          border-radius: 0;
          padding: 0 16px;
        }
        .faqs-card{
          .card-header{
            padding: 0 16px;
          }
          .card-body{
            padding: 32px 16px;
          }
        }
      }
    }
  }

  .success-creation-popup {
    .success-creation-popup-body {
      h2 {
        font-size: 19px;
      }

      p {
        font-size: 14px;
      }
    }
    .success-creation-popup-footer {
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 0;
    }
  }
}
