@use "../../../assets/scss/variables.module.scss";

.current-outstanding-balance {
  padding: 25px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  background-color: #fcfcfc;
  .current-outstanding-balance-details {
    h6 {
      font-size: 11px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }
    h2 {
      font-size: 21px;
      color: variables.$secondary;
      margin: 0px;
      em {
        font-size: 14px;
        color: #999999;
        font-weight: 400;
        margin-left: 4px;
      }
    }
  }
}

.d-flex {
  display: flex;
}

.justify-content-spacebetween {
  justify-content: space-between;
}

.single-withdrawal {
  header {
    display: flex;
    padding: 20px 16px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #efefef;
    position: relative;
    h3 {
      font-size: 18px;
      margin: 0;
    }
    .close-container {
      position: absolute;
      right: 14px;
      font-size: 18px;
      color: #999999;
    }
  }

  .single-withdrawal-body {
    .single-withdrawal-details {
      padding: 16px 24px;
      border-bottom: 1px solid #efefef;

      h6 {
        font-size: 11px;
        color: #999999;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
      p {
        font-size: 13px;
        color: #777777;
        font-weight: 600;
        margin-bottom: 8px;
      }
      h5 {
        font-size: 15px;
        font-weight: 700;
        color: #444444;
        margin-bottom: 16px;
      }

      a {
        text-decoration: none;
      }
    }

    .single-withdrawal-activity-log {
      h4 {
        margin: 0;
        font-size: 14px;
        color: #777777;
        font-weight: 400;
      }
      header {
        background-color: #f9f9f9;
        padding: 16px 24px;
        display: block;
        h4 {
          margin: 0;
          font-size: 14px;
          color: #777777;
          font-weight: 400;
        }
      }

      .single-activity {
        padding: 24px;
        border-bottom: 1px solid #efefef;

        h4 {
          margin: 0;
          font-size: 14px;
          color: #444444;
          font-weight: 400;
        }

        p {
          color: #777777;
          font-size: 11px;
        }
      }
    }
  }
}

.new-withdrawal-request {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  header {
    display: flex;
    padding: 20px 16px;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
    h3 {
      font-size: 18px;
      margin: 0 0 0 12px;
    }
    .close-container {
      position: absolute;
      right: 14px;
      font-size: 18px;
      color: #999999;
    }
  }

  .new-withdrawal-request-body {
    height: calc(100vh - 137px);
    overflow-y: auto;
    .verification-pending {
      border-bottom: 1px solid #efefef;

      .card {
        margin: 16px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        background-color: #ff9800;
        border-radius: 4px;
        padding: 16px;
        display: flex;
        align-items: center;
        p {
          color: #fff;
          margin-left: 16px;
        }
        .icon {
          color: #fff;
        }
      }
    }
    .account-details {
      padding: 16px 24px;
      border-bottom: 1px solid #efefef;
      h6 {
        font-size: 11px;
        color: #999999;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
      p {
        font-size: 13px;
        color: #777777;
        font-weight: 600;
        margin-bottom: 8px;
      }
      h5 {
        font-size: 15px;
        font-weight: 700;
        color: #444444;
        margin-bottom: 16px;
      }
    }

    .new-withdrawal-request-form {
      padding: 16px 24px;
      .form-control {
        margin-bottom: 26px;
        label {
          display: block;
          margin-bottom: 12px;
          font-size: 14px;
          color: #000;
          font-weight: 600;
        }
        input {
          box-sizing: border-box;
          border: 1px solid #ced4da;
          border-radius: 5px;
          height: 40px;
          width: 100%;
          padding: 0 8px;

          &:focus {
            outline: none;
          }
        }

        .upload-button {
          position: relative;
          button {
            position: absolute;
            font-size: 14px;
            height: 28px;
            width: 80px;
            border-radius: 5px;
            color: #fff;
            background: #009688;
            border: 0;
            top: 6px;
            left: 6px;
            cursor: pointer;
            &:disabled {
              background-color: rgba(0, 150, 136, 0.33);
              cursor: default;
            }
          }
          input {
            background: #fff;
          }
        }

        .error-message {
          color: variables.$primary;
          font-size: 13px;
        }
      }
    }
  }

  .new-withdrawal-footer {
    box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.08);
    padding: 16px;
  }
}
.withdrawals-table-mobile {
  .withdrawals-table-single-mobile {
    padding: 16px;
    border-bottom: 1px solid #efefef;
    background-color: #fff;

    h6 {
      font-size: 11px;
      font-weight: 500;
      color: #999999;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    p {
      font-size: 13px;
      color: #777777;
      font-weight: 600;
      margin-bottom: 8px;
    }
    h5 {
      font-size: 15px;
      font-weight: 600;
      color: #444444;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .current-outstanding-balance {
    flex-direction: column;
    .current-outstanding-balance-details {
      text-align: center;
      h6 {
        margin-bottom: 5px;
      }
      h2 {
        font-weight: 600;
        em {
          margin: 8px 0;
          display: block;
        }
      }
    }
  }
}

.cancel-request-body {
  padding: 36px 24px 18px;
  text-align: center;
  position: relative;
  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    color: #999999;
    cursor: pointer;
  }

  .request-header {
    margin-bottom: 16px;
    h4 {
      font-size: 17px;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      color: #999999;
    }
  }
  .withdrawal-details {
    margin-bottom: 16px;
    p {
      color: #777777;
      font-size: 13px;
      margin-bottom: 4px;
    }
    h5 {
      font-size: 15px;
    }
  }
}
.cancel-request-footer {
  border-top: 1px solid #efefef;
  padding: 24px;
  display: flex;
  gap: 8px;
}
