@use "../scss/variables.module.scss";

@media only screen and (max-width: 767px) {
  .responsive-margin {
    margin-bottom: 56px;
    word-break: break-word;
    // margin-top: 50px;
    .mt-5 {
      margin-top: 35px !important;
    }
  }
  .mobile-margin {
    margin: 0px 10px;
  }

  .small-screen-hidden {
    display: none;
  }

  .gallery {
    .gallery-showcase {
      height: auto;
      margin: 0;
      .gallery-image {
        object-fit: contain;
        // max-width: 710px;
        width: 100%;
        height: auto;
        margin-bottom: 8px;
      }
    }
  }

  .edit-gallery {
    .gallery-showcase-container {
      margin-bottom: 5px;
      .gallery-showcase {
        object-fit: contain;
        // max-width: 710px;
        width: 100%;
        height: auto;
        margin-bottom: 8px;
      }
    }
  }

  .edit-amount-raised {
    .amount-raised {
      display: flex;
      align-items: center;
      p {
        color: #666666;
        font-size: 16px;
      }
      .amount {
        font-size: 26px;
        font-weight: 700;
        color: #999999;
        border-bottom: 1px dashed #000000;
        margin-right: 8px;
      }
      .total-amount-container {
        width: 110px;
      }
    }
  }
}

.other-button-mobile {
  .MuiOutlinedInput-input {
    padding: 5px;
    height: 26px;
    width: 100%;
  }
}

.other-button-selected-mobile {
  .MuiOutlinedInput-input {
    color: white;
    font-size: 14px;
  }
  .MuiInputAdornment-root {
    p {
      color: white;
    }
  }
}

.counter {
  .span {
    display: inline-block;
    width: 32px;
    height: 32px;
    color: variables.$primary;
    border: 1px solid #dddddd;
    line-height: 28px;
    text-align: center;
    font-size: 22px;
    padding: 0;
  }
  .input-field {
    font-size: 16px !important;
    &::placeholder {
      color: variables.$primary;
    }
  }

  .decrement-button {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .increment-button {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .small-screen-hidden {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .big-screen-hidden {
    display: none;
  }
}
