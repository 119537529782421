.dashboard-drawer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
.dashboard-drawer-header {
    display: flex;
    padding: 20px 24px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    h3 {
        font-size: 18px;
        margin: 0;
    }

    svg {
        position: absolute;
        right: 14px;
        font-size: 18px;
        color: #999999;
    }
  }

  .dashboard-drawer-body {
    padding: 16px 24px;
    border-bottom: 1px solid #efefef;
    text-align: center;

    .dashboard-drawer-user-details{
        h4 {
            font-size: 20px;
            color: #444444;
            margin-bottom: 4px;
        }
        p {
            font-size: 15px;
            color: #777777;
        }
    }
    .dashboard-drawer-user-added-on {
        margin-top: 12px;
        font-size: 11px;
        text-transform: uppercase;
        color: #777777;
    }
    .dashboard-drawer-user-action {
        margin-top: 18px;
    }
    .dashboard-drawer-user-actions-btn {
        background: #fcfcfc;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        margin-right: 8px;
        text-decoration: none;
        font-weight: 600;
        text-transform: capitalize;
    }
  }
  .dashboard-drawer-footer {
    margin-top: auto;
    padding: 12px 24px;
    box-shadow: 0px -3px 4px rgba(0,0,0,0.08);
  }
}