@use "./assets/scss/variables.module.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-quill/dist/quill.snow.css";
@import "./assets/scss/main.scss";
// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

.active {
  font-weight: 700;
}

.home_page_carousel_arrow {
  // margin-top: 70px;

  .control-dots {
    bottom: -40px !important;
    // margin-left: 20px !important;
    text-align: center !important;

    li {
      position: relative;
      display: table-cell !important;
      cursor: pointer;
    }
  }
}

.homepage_banner_skeleton_ease_in {
  transition: opacity 3s ease-in-out;
  opacity: 1;
}

.homepage_banner_skeleton_ease_out {
  transition: opacity 3s ease-in-out;
  opacity: 0;
}

.progress_wrapper {
  .MuiLinearProgress-root {
    height: 8px;
    border-radius: 0.3rem;
  }

  .MuiLinearProgress-barColorPrimary {
    background: #ff5050 !important;
    border-radius: 0.3rem;
  }
}

.supporter-process-bar.progress {
  height: 8px !important;
}

.supporter-process-bar .progress-bar {
  background: #ff5050 !important;
}

.monthly-count-supporters {
  color: variables.$tertiaryDark !important;
}

.completed-supporters-homepage {
  float: right;
  color: variables.$primaryDark !important;
}

.button-wrapper {
  span.MuiButton-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: none;
  }
}

body.main_body_element {
  overflow: hidden;
}

.MuiPopover-root  {
  z-index : 99999999999999 !important
}
@media only screen and (max-width: 959px) {
  .home_page_carousel_arrow {
    // margin-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .home_page_carousel_arrow {
    // margin-top: 50px;

    .control-dots {
      text-align: center !important;
      bottom: -50px !important;
    }
  }
}

//currency symbol font family
.currency-font-roboto {
  font-family: roboto;
}

//currency symbol font family
.currency-font-inter {
  font-family: "Inter", sans-serif;
}

//Custom Currency dropdown CSS
.currency_select {
  width: 120px;
  padding: 12px 12px 12px 16px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: 24px;
  justify-content: center;
  position: relative;
  background-color: #fff;
  cursor: pointer;
}

// @media only screen and (max-width: 767px) {
//     .currency_select {
//         width: auto;
//         padding: 6px 4px 6px 12px;
//     }

//     .currency_dropdown {
//         width: auto;
//     }
// }

.currency_select:hover {
  border: 1px solid #999999;
}

.currency_select span {
  /* margin:0 5px 0 0; */
  /* font-family: 'Poppins'; */
  font-weight: 400;
  font-size: 1rem;
  color: #101828;
}

.currency_select svg {
  fill: #999999;
  font-size: 24px;
}

.currency_select.donor-select {
  width: max-content;
  min-width: 165px;

  .currency_dropdown {
    width: max-content;
    min-width: 165px;
  }
}

.tip_drop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.currency_dropdown {
  width: 120px;
  // width: 100%;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  position: absolute;
  top: 55px;
  left: 0;
  background-color: #fff;
  z-index: 10002;
}

.currency_dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: unset !important;
}

.currency_dropdown ul li {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #666666;
  cursor: pointer;
  width: 100%;
  margin: 0px !important;
  box-sizing: border-box;
}

.currencyList {
  padding: 9px 16px;
}

.currency_dropdown ul li:hover {
  color: #e7424b;
  background: rgba(253, 242, 226, 0.5);
}

//Common Button/CTA
.primary_cta {
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 600;
  font-family: Poppins;
  padding: 12px 16px;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: none;
  color: #fff;
  background-color: #e7424b;
  box-shadow: none;
  cursor: pointer;

  &:hover {
    box-shadow: none;
    background-color: #f48587;

    @media only screen and (max-width: 767px) {
      background-color: #e7424b;
    }
  }
}

.secondary_cta {
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 600;
  font-family: Poppins;
  padding: 11px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: none;
  color: #222222;
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid #999;

    @media only screen and (max-width: 767px) {
      border: 1px solid #dedede;
    }
  }
}

.partner-logo {  
  padding: 12px 17px;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: none;
  color: #222222;
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);  
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media only screen and (max-width: 767px) {
    box-shadow: none;
    position: absolute;
    bottom: 8px;
    left: 16px;
    width: 25%;
    padding: 6px 8px;
  }
}

//Button default infinite shiny loading
@keyframes shinyLoader {
  0% {
    background-position: -5000px 0;
  }

  to {
    background-position: 5000px 0;
  }
}

.animate-shiny-button {
  animation: shinyLoader 53s linear infinite;
  background: linear-gradient(121.19deg,
      rgba(133, 239, 49, 0) 25.73%,
      hsla(0, 0%, 100%, 0.3) 45.27%,
      rgba(133, 239, 49, 0) 62.27%),
    #e7424b;
}

// Partners

.fadeInfadeOut {
  animation: fadeInOutAnimation 5000ms infinite;
}

.fadeInfadeOut .monthly-mission {
  animation: fadeInOutAnimation 7000ms infinite;
}

@keyframes fadeInOutAnimation {
  0% {
    opacity: 0.01;
  }

  25% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.8;
  }

  95% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.01;
  }
}

// masonary CSS

.my-masonry-grid {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  margin-left: -32px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 32px;
  box-sizing: border-box;
}

.my-masonry-grid_column>.item {
  margin: 0 0 20px;
}

.my-masonry-grid_column>.item iframe {
  min-width: 100% !important;
  max-width: 100% !important;
}

.news_section {
  margin: 0 -20px;

  @media only screen and (max-width: 767px) {
    margin: 0 -16px;
  }
}
.carouselBe{
.carousel.carousel-slider{
  padding-bottom: 40px;
  @media only screen and (max-width: 960px) {
    padding-bottom: 24px;
  }
}
  .control-dots{
    height: 8px;  
    margin:0 !important;
    overflow: hidden;
  }
}

.news_section,
.blog_section {
  position: relative;

  .carousel.carousel-slider {
    position: unset;
  }

  .carousel .slide {
    padding: 0px 20px;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
      padding: 0 16px;
    }
  }

  .carousel .slider-wrapper.axis-horizontal .slider {
    align-items: flex-start;
  }

  .control-dots {
    line-height: 1;
    bottom: -35px !important;

    li {
      line-height: 8px;
    }
  }
}

.blog_section {
  .control-dots {
    text-align: left !important;
    left: 16px !important;
    width: auto !important;
  }

  @media only screen and (max-width: 767px) {
    left: 0px !important;
    margin: 0 -16px;

    .control-dots {
      left: 50% !important;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}

.cause_blog_section {
  .control-dots {
    left: 50% !important;
    transform: translateX(-50%);
    bottom: -55px !important;
  }

  @media only screen and (max-width: 767px) {
    left: 0px !important;
    // margin: 0 -16px;
    margin: 0;

    .control-dots {
      // left: 50% !important;
      // transform: translateX(-50%);
      transform: unset !important;
      left: 0 !important;
      text-align: left !important;
      white-space: nowrap;
    }
  }
}

.sticky {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
}

// VideoPlayer

.custom-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 800px;
  height: 515px;
  background-color: #000;
  border-radius: 8px;
  outline: none;

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100dvh;
    background: none;
  }
}

.custom-modal iframe {
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: 767px) {
    border-radius: 8px;
  }
}

.custom-overlay {
  background: rgba(0, 0, 0, 0.60);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1101;

  @media only screen and (max-width: 767px) {
    background: rgba(0, 0, 0, 90%)
  }
}

.videoImg {
  width: 120px !important;
  height: 120px !important;
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    width: 70px !important;
    height: 70px !important;
  }
}

.modal-open {
  overflow: hidden;
}

.videoDesign {
  position: absolute;
  top: calc(50% - 34px);
  transform: translateY(-50%);
}

.videoDesign>div {
  position: absolute;
  top: 0;
  left: 0;
}

// react-toastify css overrides
.Toastify__toast-container {
  width: auto!important;
  padding: 0px !important;
  @media only screen and (max-width: 767px) {
    top: 24px !important;
    left:50% !important;
    transform: translateX(-50%) !important;
  }
  .Toastify__toast-body {
    padding: 0px !important;
  }
}

.Toastify__toast-theme--light {
  background: #fff;
  border-radius: 4px !important;
}

.Toastify__toast {
  padding: 0!important;
  margin: 0!important;
}

.Toastify__close-button--light {
  display: none !important;
}

.supportersWrapperImage {
  display: inline-flex !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: rgba(253, 242, 226, 0.5);
  color: #E7424B;
}

.react-tel-input .country-list .country:hover {
  background-color: rgba(253, 242, 226, 0.5);
}
.react-tel-input .country-list .search-emoji{
  display: none;
}

.btn-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  background-color: #fff;
}