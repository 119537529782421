@import "./selectTypeOfFundraiser.scss";
@import "./selectProgram.scss";
@import "./enterFundraiserDetails.scss";
@import "./createMedicalFundraiser.scss";
@import "./createFundraiserLogin.scss";
@import "./completePendingTasks.scss";

.navbar-container {
  border-bottom: 1px solid #e3e3e3;
}
.no_projects{
  border: 1px solid #EFEFEF;
  border-radius: 12px;
  padding: 25px;
  margin: 0 0 50px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 50px;
    margin: 0 0 15px;
  }
  h6{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
}
