@use "../scss/variables.module.scss";

.gifr-header {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  h1 {
    font-weight: 700;
    text-align: center;
    font-family: variables.$secondaryFont;
  }
}

@media only screen and (max-width: 767px) {
  .gifr-header {
    padding: 0 10px;
    box-sizing: border-box;
    h1 {
      font-weight: 400;
    }
  }
}
