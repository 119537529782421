@use "../variables.module.scss";

.create-fundraiser-select-program {
  .create-fundraiser-search-program {
    text-align: center;
    margin-bottom: 20px;
    box-sizing: border-box;
    p {
      margin: 22px 0 16px;
      color: rgba(102, 102, 102, 0.66);
      small {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 700;
      }
    }
    h2 {
      font-size: 23px;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 700;
      margin: 0 0 22px;
    }
    .search-bar-container {
      position: relative;
      max-width: 720px;
      width: 100%;
      margin: auto;
      i {
        position: absolute;
        top: 18px;
        left: 16px;
        color: rgba(0, 0, 0, 0.33);
      }

      input {
        box-sizing: border-box;
        background-color: #f3f3f4;
        height: 50px;
        max-width: 706px;
        width: 100%;
        border: 0;
        padding-left: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:focus {
          outline: 0;
        }

        &::placeholder {
          color: rgba(0, 0, 0, 0.33);
        }
      }
    }
  }

  .cause-slider-container {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 6px 16px;
    margin-bottom: 38px;
    overflow: hidden;
    .cause-slider {
      .skeleton-text-container {
        width: 120px !important;
        margin-left: 16px !important;
      }
      .slick-slider {
        display: flex;
        align-items: center;
        .slick-list {
          width: 100% !important;
          margin: 0 20px;
          .slick-track {
            display: flex;
            flex-wrap: nowrap;
            .slick-slide {
              white-space: nowrap;
            }
          }
          @media only screen and (max-width: 767px) {
            padding: 0 10px;
          }
        }
      }
      .cause-single {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 38px;
        margin: 0 32px 0 0;
      }
      .active-cause {
        background-color: variables.$secondary;
        border-radius: 5px;
        p {
          color: white;
          font-weight: 700;
        }
      }
      p {
        color: #999999;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  .program-list {
    padding: 0 16px;
    box-sizing: border-box;
    .program-list-header {
      margin-bottom: 36px;
      .non-profit-selected {
        display: flex;
        align-items: center;
        h3 {
          margin: 0;
        }
        .ngo-details {
          display: flex;
          align-items: center;
          margin-left: 16px;
          border: 1px solid rgba(225, 225, 225, 0.66);
          border-radius: 25px;
          padding: 12px 16px;

          .ngo-card {
            width: 32px;
            height: 32px;
            border-radius: 2px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 28px;
              height: 28px;
            }
          }

          p {
            font-size: 15px;
            font-weight: 700;
            color: #444444;
            margin-right: 8px;
          }
        }
      }
      h3 {
        font-size: 19px;
        color: #212121;
        margin: 0 0 8px;
      }

      p {
        color: #777777;
        font-size: 14px;
        margin: 0;
        font-weight: 600;

        span {
          color: #999999;
        }
        .active {
          color: variables.$secondary;
          font-weight: 700;
        }
      }
    }

    .program-list-body {
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 40px;
      // flex-wrap: wrap;

      .program-card {
        margin-bottom: 24px;
        .card-header {
          height: 125px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          background-size: contain !important;
          background-repeat: no-repeat !important;
          background-position: center center !important;
          position: relative;
          padding: 0 19px 10px;
          display: flex;
          align-items: flex-end;
          .urgent-tag {
            position: absolute;
            top: 10px;
            right: 0;
            background: variables.$primary;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            height: 26px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            p {
              font-size: 11px;
              text-transform: uppercase;
              letter-spacing: 1px;
              color: #ffffff;
            }
          }
        }

        .card-body {
          .program-name {
            padding: 10px 16px;
            border-bottom: 1px solid #f2f2f2;
            box-sizing: border-box;
            min-height: 95px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h5 {
              font-size: 18px;
              color: #212121;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              min-height: 55px;
            }
            p {
              span {
                color: #666666;
                font-size: 13px;
                margin-right: 4px;
              }
            }
          }

          .program-stats {
            display: flex;
            border-bottom: 1px solid #f2f2f2;
            background-color: #fcfcfc;
            .single-detail {
              width: 50%;
              padding: 10px 16px;
              text-align: center;
              p {
                font-size: 11px;
                color: #999999;
                margin-bottom: 8px;
              }
              h6 {
                font-size: 15px;
                color: #444444;
              }
            }
            .border-right {
              border-right: 1px solid #f2f2f2;
            }
          }

          .active-fundraisers {
            background-color: #fcfcfc;
            padding: 10px 16px;
            text-align: center;
            border-bottom: 1px solid #f2f2f2;
          }

          .ngo-name {
            padding: 10px 16px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f2f2f2;

            div {
              width: 50px;
              height: 50px;
              margin-right: 16px;
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 40px;
              }
            }
            p {
              font-size: 14px;
              color: #444444;
              font-weight: 700;
            }
          }

          .card-action {
            padding: 16px;
            display: flex;
            gap: 16px;
          }
        }
      }
    }

    .program-list-footer {
      text-align: center;
      margin: 0 0 30px;

      .show-result {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid variables.$primary;
        width: 160px;
        height: 31px;
        margin: auto;
        border-radius: 3px;
        cursor: pointer;
        p {
          font-size: 14px;
          margin-right: 8px;
          color: variables.$primary;
        }
        i {
          font-size: 12px;
          color: variables.$primary;
        }
      }
    }
  }
}

.info-icon {
  width: 16px !important;
  height: 16px !important;
}

.sliderArrows {
  width: 100%;
  max-width: 38px;
  height: 38px;
  border-radius: 19px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .right {
    width: 20px;
    height: 20px;
    object-fit: contain;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.view-program-popup {
  position: relative;
  .close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .view-program-header {
    h3 {
      margin: 0;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 28px;
    }

    border-bottom: 1px solid #efefef;
    margin-bottom: 28px;
  }

  .view-program-body {
    .ngo-name {
      margin-bottom: 32px;
      .ngo-card {
        width: 50px;
        height: 50px;
        margin-right: 16px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 40px;
        }
      }

      p {
        color: #444444;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
      }
    }

    .program-single-info {
      margin-bottom: 32px;

      h4 {
        margin: 0 0 16px;
        color: #212121;
        font-size: 19px;
      }

      ol {
        padding-left: 16px;
        margin: 0 0 32px;
        color: #777777;
      }

      .inner-html-content {
        p {
          font-size: 16px;
          color: #777777;
          margin-bottom: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .create-fundraiser-select-program {
    .create-fundraiser-search-program {
      padding: 0 16px;
    }

    .cause-slider-container {
      padding: 6px 16px 6px;
      margin-bottom: 28px;
    }
    .program-list {
      .program-list-header {
        padding: 0 16px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 28px;

        h3 {
          font-size: 15px;
          text-align: center;
        }
        p {
          font-size: 13px;
          margin-bottom: 16px;
          text-align: center;
          span {
            font-size: 14px;
          }
        }

        .program-filter {
          margin: 0 auto;
          p {
            margin-bottom: 0;
          }
        }
      }

      .program-list-body {
        padding: 0 16px;
        box-sizing: border-box;
      }
    }
  }
}
