@use "../scss/variables.module.scss";

.story-tab {
  margin-top: 40px;

  iframe {
    width: 100%;
    height: 373px;
  }

  @media only screen and (min-width:768px) {
    margin-top: 60px;
  }

  @media (max-width:767px) {
    margin-top: 30px;
  }

  .campaign-project {
    border-bottom: 1px solid variables.$headingBorder;
    padding-bottom: 16px;
  }

  .campaign_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 24px;
    margin-bottom: 24px;

    // line-height: 38px;
    // margin-top: 40px;
    @media (max-width:767px) {
      font-size: 1.125rem;
      margin-bottom: 16px;
    }
  }

  .non-profit {
    h3 {
      margin-top: 0;
    }

    .ngo-details {
      margin-bottom: 16px;

      p {
        margin: 0;
      }
    }

    p {
      margin: 0 0 15px;
    }
  }
}

.story-tab.updates {
  .campaign_title {
    margin-bottom: 0px;
  }
}

.tab-content-div:first-child {
  section {
    margin-top: 0;
  }
}