@use "../scss/variables.module.scss";
@import "./donors.scss";
@import "./navbar.scss";
@import "./header.scss";
@import "./reportCampaign.scss";
@import "./main-tab.scss";
@import "./faqTab.scss";
@import "./storyTab.scss";
@import "./invitation.scss";
@import "./givenow.scss";
@import "./medicalCampaign.scss";
@import "./supporters.scss";
@import "./socialLinks.scss";
@import "./updates.scss";
@import "./thankyou.scss";
@import "./closedFundRaiser.scss";
@import "./imageGallery.scss";
@import "./editMode.scss";
@import "./dialog.scss";
@import "./footer.scss";
@import "./paymentMethods.scss";
@import "./createFundraiser/createFundraiser.scss";
@import "./childCampaign.scss";
@import "./supportingCampaign.scss";
@import "./unitFundRaiser.scss";
@import "./rewardFundRaiser.scss";
@import "./programCard.scss";
@import "./dashboard.scss";
@import "./dashboardDrawer.scss";
@import "./fundraiserRewamp.scss";
@import "./newsletter.scss";
@import "./impactTab.scss";
@import "./common.scss"; // make sure common is always at the end but before mobile
@import "./mobile.scss"; //make sure mobile is always at the bottom

body {
  margin: 0;
  font-family: variables.$primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
}

code {
  font-family: variables.$primaryFont;
}

.facebook-btn {
  background-color: variables.$fbIconBgColor !important;
  color: variables.$white !important;
  text-transform: capitalize !important;
  margin-bottom: 10px !important;
}

.whatsapp-btn {
  background-color: variables.$whatsappIconColor !important;
  color: variables.$white !important;
  text-transform: capitalize !important;
  margin-bottom: 10px !important;
}

.tabs {
  .MuiTab-root {
    padding: 0px !important;
    width: 100%;
    max-width: 150px;
    min-width: 0px;
  }

  .MuiTab-wrapper {
    align-items: flex-start !important;
    text-transform: none;
  }

  .MuiTabs-indicator {
    max-width: 130px;
    // width: 100%;
  }

  .Mui-selected {
    color: variables.$primaryProgressBar;
  }
}

.notification-badge {
  position: absolute;
  top: 20px;
  left: auto;
  right: 5px;
}

@media only screen and (min-width: 768px) {
  .update-tab .notification-badge {
    left: 60px;
    right: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .tabs {
    .MuiTab-root {
      max-width: 130px;
    }
  }
}

.mobile-tabs {
  .MuiTab-root {
    padding: 0px 20px !important;
    font-weight: 800 !important;
  }

  .MuiTabs-centered {
    justify-content: space-evenly !important;
  }
}

.crop-image {
  .ReactCrop__image {
    height: 380px;
  }
}

.edit-campaign-title {
  .MuiOutlinedInput-input {
    text-align: center;
    font-size: 28px;
    font-family: "Libre Baskerville", "serif";
    font-weight: 700;
  }
}

.edit-textfield {
  .MuiOutlinedInput-input {
    padding: 12px 14px;
    height: 33px;
  }
}

.country-code-input {
  .MuiOutlinedInput-input {
    padding-left: 100px;
  }
}

.edit-donate-textfield {
  .MuiOutlinedInput-input {
    text-align: center;
    padding: 12px 14px;
  }
}

.media-url-textfield {
  .MuiOutlinedInput-input {
    text-align: center;
    padding: 12px 14px;
    width: 350px;
  }
}

.edit-campaign-story {
  margin-top: 16px;

  .MuiBox-root {
    padding-left: 0px !important;
  }
}

.faq-textfield {
  .MuiOutlinedInput-root {
    border-radius: 0px !important;
  }

  .MuiOutlinedInput-input {
    padding: 12px 14px !important;
  }
}

.url-textfield {
  .MuiOutlinedInput-input {
    padding: 14px 14px !important;
  }
}

.clamp-lines__button {
  background: none;
  border: 0;
  color: variables.$secondary;
  cursor: pointer;
  padding: 0;
}

.drag-frame {
  opacity: 0.5;
  border: 4px dotted variables.$secondary;
}

.other-button {
  margin: 0px;
  width: 100%;

  .MuiOutlinedInput-input {
    padding: 7px 10px;
    height: 26px;
    max-width: 126px;
    width: 100%;
    font-size: 14px;
  }

  .MuiOutlinedInput-adornedStart {
    width: 100%;
  }
}

.monthly-sub-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.other-button-thankyou {
  .MuiOutlinedInput-input {
    padding: 7px 0px;
    height: 22px;
    max-width: 126px;
    width: 100%;
    font-size: 14px;
  }
}

.donations {
  .MuiFormControl-root {
    display: flex;
  }
}

.other-button-selected {
  .MuiOutlinedInput-input {
    color: variables.$white;
    font-size: 14px;
  }

  .MuiInputAdornment-root {
    p {
      color: variables.$white;
    }
  }
}

.mobile-other-button {
  width: 100%;

  .MuiOutlinedInput-input {
    font-size: 17px;
    height: 50px;
    padding: 5px;
  }

  .MuiOutlinedInput-adornedStart {
    width: 100%;
  }
}

.login {
  .MuiDialog-paperWidthSm {
    max-width: 400px;
    width: 100%;
  }
}

.MuiTabPanel-root {
  padding: 5px !important;
}

.tab-size-div {
  .MuiBox-root {
    padding: 32px 0px !important;
  }
}

.link-button {
  display: none;
}

.parent-container {
  width: 100%;
  margin: 0 auto;
}

.parent-container.create-fundraiser {
  max-width: 1200px;
}

.parent-container.create-fundraiser.parent-footer {
  max-width: 100%;
}

.gifr-main-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;

  // column-gap: 50px;
  .gifr-left-panel {
    margin-right: 25px;
    flex-basis: 710px;
  }

  .gifr-right-panel {
    margin-left: 25px;
    flex: 0 0 440px !important;
  }
}

.support-faq {
  p {
    color: variables.$tertiaryDark;
    font-size: 14px;
  }

  .span-faq {
    a {
      text-decoration: none;
      color: variables.$secondary;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.layout-visibility {
  display: block;
}

.mobile-visibility {
  display: none;
}

.donation-capsules button,
.donation-capsules .other-button {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .gifr-main-content {
    // column-gap: 25px;

    .gifr-left-panel {
      margin-right: 12px;
    }

    .gifr-right-panel {
      margin-left: 12px;
      flex: 0 0 340px !important;
    }
  }

  .tabs {
    .MuiTab-root {
      padding: 0px 24px 0 0 !important;
      width: auto;
      max-width: 150px;
      min-width: 0px;
    }

    .MuiTabs-indicator {
      max-width: auto !important;
      // width: 100% !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .gifr-main-content {
    display: flex;
    padding: 0;

    // column-gap: 0px;
    .gifr-left-panel {
      margin-right: 0;
      flex-basis: 100%;
    }

    .gifr-right-panel {
      margin-left: 0;
      flex: 0 0 0px !important;
    }
  }

  .layout-visibility {
    display: none;
  }

  .mobile-visibility {
    display: block;
  }

  .login {
    .MuiDialog-paperWidthSm {
      margin: 0;
      height: 100vh;
      margin-top: 111px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mobile-buttons {
    display: inline;
  }

  .other-button {
    .MuiOutlinedInput-input {
      padding: 7px 0px;
      height: 26px;
      max-width: 126px;
      width: 100% !important;
      font-size: 14px;
    }
  }

  .other-button-thankyou {
    .MuiOutlinedInput-input {
      padding: 7px 0px;
      height: 22px;
      max-width: 126px;
      width: 100%;
      font-size: 14px;
    }
  }
}

.update-carousel {
  z-index: 1400;
  width: 100%;

  ul {
    padding: 0 !important;

    li {
      overflow-y: scroll !important;
    }
  }

  .gallery-image-div {
    img {
      object-fit: fill !important;
    }
  }
}

.dashboard-btn-wrapper {
  margin: 30px 0;
  width: 100%;
  padding: 20px 15px 30px;
  box-sizing: border-box;
  background-color: variables.$tertiaryGrey;
  border-radius: 5px;

  h3 {
    margin: 0 0 10px;
    font-size: 20px;
    line-height: 26px;
  }

  p {
    margin: 0 0 20px;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;

    .dashboard-btn {
      padding: 12px 15px;
      margin: 0 10px;
      border: 0;
      outline: none;
      border-radius: 3px;
      font-size: 16px;
      color: variables.$white;
      background-color: variables.$secondary;
      text-decoration: none;
      cursor: pointer;
    }

    .dashboard-btn:first-child {
      margin-left: 0;
    }
  }
}

.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: variables.$white;
  flex-direction: column;
}

.loader-wrap img {
  width: 280px;
}

.discover-btn {
  color: variables.$primary;
  cursor: pointer;
}

.btn-primary-outlined {
  padding: 7px 21px;
  color: variables.$primary;
  border: 1px solid rgba(255, 80, 80, 0.5);
  font-size: 0.9375rem;
  line-height: 1.75;
  border-radius: 4px;
  font-weight: 400;
  // Mari
  display: inline-flex;
  background: variables.$white;
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .dashboard-btn-wrapper {
    padding: 10px 10px 20px;

    .btn-wrapper {
      .dashboard-btn {
        padding: 12px;
        margin: 0 5px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .dashboard-btn-wrapper {
    border-radius: 0;

    .btn-wrapper {
      .dashboard-btn {
        padding: 12px 6px;
      }
    }
  }
}

// Fundrasier Dashboard Layout
.dashboard-loader {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.dashboard-container {
  display: flex;
  justify-content: space-between;
  background: variables.$tertiaryWhite;

  .dashboard-menu-wrapper {
    width: 20%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    background: variables.$white;
    position: relative;
    z-index: 99;
  }

  .dashboard-content {
    position: relative;
    width: 80%;
    height: 80vh;
    overflow: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  }
}

@media only screen and (max-width: 959px) {
  .dashboard-container {
    flex-wrap: wrap;

    .dashboard-content {
      width: 100%;
    }

    .dashboard-menu-wrapper {
      width: 100%;
      order: 1;
    }
  }
}

#new-withdrawal-dialog {
  ::-webkit-scrollbar {
    width: 4px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: variables.$scrollBarTrackColor !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: variables.$scrollBarGreyColor !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: variables.$scrollBarGreyColor !important;
  }
}

// Fresh-chat window in give branding
.chat-fc-form-outer {
  p.fc-header {
    font-family: variables.$primaryFont !important;
    font-size: 24px !important;
    font-weight: 800 !important;
  }

  p.fc-header .fc-minimize {
    font-family: variables.$primaryFont !important;
    font-size: 14px !important;
    margin-right: 0 !important;
    margin-top: 5px !important;
  }

  div.fc-form {
    font-family: variables.$primaryFont !important;

    p {
      margin-top: 20px;
    }

    ul li input {
      font-family: variables.$primaryFont !important;
      padding-left: 5px !important;
      font-size: 15px !important;
      height: 23px;
      margin-bottom: 5px;
    }
  }
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.fr_section_wrap {
  background: #FAF9F9;
}

.list-cause-button {
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-right: 32px;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
  }

  p {
    color: #E7424B;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}

.list-cause-button-sticky {
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex !important;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;

  img {
    width: 24px;
    margin-right: 8px;
    height: auto;
  }

  p {
    color: #E7424B;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}

.ec_sliderArrows {
  width: 100%;
  max-width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: #FFF;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .right {
    font-size: 24px;
    color: #E7424B;
  }

  .left {
    font-size: 24px;
    color: #E7424B;
  }

  .cause-right {
    font-size: 36px;
    color: #E7424B;
  }

  .cause-left {
    font-size: 36px;
    color: #E7424B;
  }

  .cause-left.disabled,
  .cause-right.disabled {
    color: #999999
  }
}

.ec_sliderArrows_sticky {
  width: 100%;
  max-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: #FFF;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .right {
    font-size: 24px;
    color: #E7424B;
  }

  .left {
    font-size: 24px;
    color: #E7424B;
  }
}

.list-causes-container.cause-slider {
  margin-top: 24px;
  margin-bottom: 48px;

  .skeleton-text-container {
    width: 120px !important;
    margin-left: 16px !important;
  }

  .slick-slider {
    display: flex;
    align-items: center;

    .slick-list {
      width: 100% !important;
      // margin: 0 20px;
      height: 90px;
      display: flex;
      align-items: center;

      .slick-track {
        display: flex;
        flex-wrap: nowrap;

        .slick-slide {
          white-space: nowrap;
        }
      }

      @media only screen and (max-width: 767px) {
        padding: 0 10px;
      }
    }
  }

  .list-cause-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 32px 0 0;
    min-width: 124px;
    outline: none !important;
  }

  .active-cause {
    p {
      color: #E7424B;
      font-weight: 700;
      text-align: center;
    }
  }

  p {
    color: #444;
    padding-top: 12px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    outline: none !important;
  }
}

.list-causes-container.cause-slider-sticky {
  .skeleton-text-container {
    width: 120px !important;
    margin-left: 16px !important;
  }

  .slick-slider {
    display: flex;
    align-items: center;
    height: 64px;

    .slick-list {
      width: 100% !important;
      // margin: 0 20px;
      height: 90px;
      display: flex;
      align-items: center;

      .slick-track {
        display: flex;
        flex-wrap: nowrap;

        .slick-slide {
          white-space: nowrap;
        }
      }

      @media only screen and (max-width: 767px) {
        padding: 0 10px;
      }
    }
  }

  .list-cause-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 32px 0 0;
    min-width: 124px;
  }

  .active-cause {
    p {
      color: #E7424B;
      font-weight: 700;
    }
  }

  p {
    color: #444;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.active-cause.list-cause-button-sticky:before {
  content: '';
  border-bottom: 4px solid #E7424B;
  width: 72%;
  position: absolute;
  bottom: -4px;
}

.active-cause.list-cause-button-sticky {
  position: relative;
}

// Applied for INR donation campaign only
.match-funds-banner-box {
  padding: 32px 16px 142px !important;
}

// Explore Other Causes Slider -> Fundraiser cause based landing page
.list-causes-container.other-cause-slider {
  @media only screen and (max-width: 767px) {
    display: flex;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;
    flex-flow: wrap;
  }

  .skeleton-text-container {
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: 160px !important;
  }

  .slick-slider {
    display: flex;
    align-items: center;

    .slick-list {
      width: 100% !important;
      // margin: 0 20px;
      height: 140px;
      display: flex;
      align-items: center;

      .slick-track {
        display: flex;
        flex-wrap: nowrap;
        gap: 12px;
        margin: 10px;

        .slick-slide {
          white-space: nowrap;
        }
      }

      @media only screen and (max-width: 767px) {
        padding: 0 10px;
      }
    }
  }

  .list-othercause-button {
    text-align: center;
    margin: 0;
    outline: none !important;
    filter: grayscale(100%);

    @media only screen and (max-width: 767px) {
      margin: 0px;
      width: calc(50% - 6px);
      filter: grayscale(0%);
    }
  }

  .other-cause-title {
    color: #444;
    padding-top: 12px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .list-othercause-button:hover {
    filter: none;

    .other-cause-image {
      outline: 2px solid #E7424B;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 2px;
    }

    .other-cause-title {
      color: #E7424B;
      font-weight: 700;
      text-align: center;
      box-sizing: border-box;
    }

    @media only screen and (max-width: 767px) {
      .other-cause-image {
        width: 100%;
        height: 100%;
        outline: unset;
        padding: 0px;
      }

      .other-cause-title {
        color: #444;
        font-weight: 500;
      }
    }
  }

  .other-cause-image {
    display: block;
    width: 150px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
    padding: 2px;
    box-sizing: border-box;
    border: 2px solid transparent;
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 100%;
    }
  }

  .active-othercause {
    .other-cause-image {
      border: 2px solid #E7424B;
      border-radius: 10px;
      box-sizing: border-box;
    }

    .other-cause-title {
      color: #E7424B;
      font-weight: 700;
      text-align: center;
    }
  }

}

.html-wrapper {
  @media only screen and (max-width: 767px) {
    text-align: center;
    width: 100%;
  }

  p,
  h1 {
    width: 30%;
    color: #444;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin: 0;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      line-height: 32px;
    }
  }

  span {
    color: #E7424B;
    font-family: Libre Baskerville;
    font-size: 48px;
    font-style: italic;
    font-weight: 400;
    line-height: 56px;

    @media only screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

#cause_blog_img {
  height: 238px !important;
}

@media only screen and (max-width: 767px) {
.otpinput {
  margin-right: 14px !important;
 }
 .otpinput:last-child{
  margin-right: 0px !important;
 }
}