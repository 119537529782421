.join-team-card {
  background-color: #f5f5f5;
  padding: 5px 20px;
  h3 {
    font-size: 20px;
    color: #161616;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    color: #161616;
  }
  button {
    margin-bottom: 20px;
  }
}
