@use "../scss/variables.module.scss";

.child-campaign {
  p {
    font-size: 14px;
  }

  .parent-campaign {
    font-weight: 700;
    color: variables.$secondary;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.child-campaing-title-small {
  z-index: 100000;
  width: 100%;
  background-color: variables.$footerBgColor;
  min-height: 56px;
  display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

  .child-campaing-pera-small {
    text-align: center;
    color:variables.$primary;
    font-weight: 600;
    width: 90%;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:16px 0px;
    span{
      color: variables.$secondaryDark;
    }
    a {
      font-weight: 700;
      font-weight: 600;
      color: variables.$primary;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.child-campaing-title {
  width: 100%;
  z-index: 9999;
  background-color: variables.$footerBgColor;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .child-campaing-pera {
    text-align: center;
    color: variables.$primary;
    font-weight: 600;
    width: 90%;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:16px 0px;
    span{
      color: variables.$secondaryDark;
    }
    a {
      font-weight: 600;
      color: variables.$primary;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
