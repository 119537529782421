@use "../scss/variables.module.scss";

.body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .gi-campaigns {
    display: flex;
    flex-direction: row;
    .campaign-details {
      padding-left: 10px;
      .small {
        font-size: 16px;
        color: #666666;
      }
      .bold {
        font-size: 16px;
        color: #000000;
        cursor: pointer;
      }
    }
  }
  .money {
    display: flex;
    .raised {
      padding: 0px 15px;
      p {
        font-weight: bold;
      }
    }
  }
  .supporters {
    display: flex;
    justify-content: space-between;
    p {
      padding: 9px;
    }
  }
}
p {
  margin: 0;
  font-size: 16px;
  color: #444;
  .sub {
    font-weight: 700;
  }
}
.no-donoations {
  text-align: center;
  p {
    font-size: 16px;
    color: #444;
  }
}
.supporters-header {
  .supporting-campaigns-list {
    margin-bottom: 24px;
  }

  .list-of-supporters {
    h3 {
      padding-bottom: 16px;
      border-bottom: solid 1px #dddddd;
    }

    .single-supporter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .donation-date {
        font-size: 14px;
        color: #666666;
      }
    }
  }
  h3 {
    margin-top: 0px;
  }
  .load-more-donors {
    p {
      color: variables.$secondary !important;
      font-weight: 600;
    }
    i {
      color: variables.$secondary !important;
      font-weight: 600;
    }
  }
  .support-tabs {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    .pipe {
      display: inline-block;
      vertical-align: middle;
      padding: 5px 10px;
    }

    .MuiTab-root {
      // min-width: 100px;
      min-height: 4px !important;
      min-width: auto;
      padding: 0px;
      height: auto;
    }
    .MuiTabs-root {
      display: flex;
      overflow: hidden;
      min-height: auto !important;
    }
    .MuiTabs-indicator {
      display: none !important;
    }
    .MuiTab-wrapper {
      align-items: end !important;
    }
    .MuiTab-textColorInherit {
      opacity: 1;
      color: #000000;
      font-weight: 600;
    }
    .Mui-selected {
      color: variables.$secondary;
    }
    .makeStyles-tabs-75 {
      height: auto !important;
      margin-top: 0px !important;
      min-height: auto !important;
      line-height: 1em !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .supporters-header {
    .support-tabs {
      justify-content: flex-start;
    }
  }
}
