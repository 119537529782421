@use "../variables.module.scss";

.select-type-of-fundraiser {
  .select-type-header {
    padding: 28px 16px;
    text-align: center;
    margin: 32px 0;
    h1 {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.87);
      margin: 0 0 9px;
    }

    h2 {
      color: #999999;
      font-size: 16px;
    }
  }

  .select-type-body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 36px;
    padding: 0 16px;
    p {
      color: #999999;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .fundraiser-type-card {
      .card-header {
        text-align: center;

        i {
          font-size: 62px;
          margin-bottom: 16px;
        }
        .red {
          color: variables.$primary;
        }

        .green {
          color: variables.$primary;
        }
      }

      .card-body {
        text-align: center;
        h2 {
          font-size: 24px;
          margin: 0;
          color: #212121;
          line-height: 1.2em;
          min-height: 2.4em;
          margin: 0 auto 16px;
          max-width: 80%;
        }

        p {
          color: #999999;
          font-size: 16px;
          margin-bottom: 16px;
          min-height: 3.5em;
        }
      }

      .card-footer {
        p {
          font-size: 14px;
          color: #444444;
          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .select-type-footer {
    text-align: center;
    margin: 0 0 30px;
    p {
      color: #666666;
      font-size: 14px;

      a {
        color: variables.$secondary;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .select-type-of-fundraiser {
    .select-type-header {
      margin-bottom: 16px;
    }
    .select-type-body {
      flex-direction: column;
    }

    .select-type-footer {
      padding: 0 16px;
    }
  }
}
