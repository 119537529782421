.footer {
  line-height: 24px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .footer-links {
    text-decoration: none;
    outline: none;
    color: #444;
  }
  .top-footer {
    border-top: 1px solid rgba($color: #000, $alpha: 0.1);
  }
  .choose-currency {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 15px 0px;
    .dropdown {
      display: flex;
      align-items: center;
      p {
        font-size: 17px;
        font-weight: 600;
        margin-right: 10px;
      }
      .select-dropdown {
        color: #0b0b0b;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 5px;
        padding: 5px 10px;
        width: 130px;
        height: 40px;
        text-align: center;
      }
    }
  }
  .about-section {
    border-top: 1px solid rgba($color: #000, $alpha: 0.1);
    h2 {
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      margin: 15px 0;
      color: #999;
    }
    p {
      color: #999;
      margin-bottom: 15px;
      font-weight: 600;
    }
    li {
      font-size: 15px;
      font-weight: 600;
      color: #999;
    }
  }
  .bottom-footer {
    border-top: 1px solid rgba($color: #000, $alpha: 0.1);
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .copyright {
      margin: 15px 0px;
      span {
        color: #999;
        font-size: 15px;
        margin-right: 35px;
        font-weight: 600;
      }
    }
    .social-links-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ul {
        margin: 0;
        list-style: none;
        padding: 0;
        display: inline-block;
      }
      ul:first-child {
        margin: 0 15px 0 0;
      }
      li {
        float: left;
        &:last-child {
          i {
            margin-right: 0;
          }
        }
      }
      margin: 15px 0px;
      a {
        text-decoration: none;
      }
      span {
        color: #999;
        font-size: 15px;
        margin-right: 10px;
        font-weight: 600;
      }
      .fab {
        margin-right: 20px;
        font-size: 20px;
        color: #999;
        cursor: pointer;
      }
    }
  }
}
.givenow-popup {
  position: relative;
  min-height: 100vh;
  padding: 15px;
  h2 {
    margin-bottom: 20px;
    padding-top: 10px;
    font-size: 24px;
    text-align: center;
  }
  i {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 25px;
  }
  .description {
    margin-bottom: 15px;
  }
}
.mobile-display {
  display: none;
}
@media only screen and (max-width: 1300px) {
  .footer {
    .bottom-footer {
      .social-links-footer {
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    .choose-currency {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 0px;
    }
    .bottom-footer {
      margin-bottom: 70px;
      border-top: 1px solid rgba($color: #000, $alpha: 0.1);
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: space-between;
      .copyright {
        margin: 15px 0px;
        display: flex;
        flex-direction: column;
        span {
          color: #999;
          font-size: 15px;
          margin-right: 0px;
          font-weight: 600;
        }
      }
      .social-links-footer {
        flex-direction: column;
        align-items: flex-start;
        ul:first-child {
          margin: 0 0 15px;
        }
        a {
          text-decoration: none;
        }
        span {
          color: #999;
          font-size: 15px;
          margin-right: 20px;
          font-weight: 600;
        }
        .fab {
          margin-right: 20px;
          font-size: 20px;
          color: #999;
          cursor: pointer;
        }
      }
    }
  }
  .mobile-display {
    display: block;
  }
}
