@use "../scss/variables.module.scss";

.medical-details {
  background-color: #f2f2f2;
  padding: 20px 30px;
  border-radius: 4px;
  label {
    color: #444444;
    font-size: 16px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    color: #000000;
    font-weight: 700;

    small {
      color: #666666;
      font-size: 14px;
    }
  }
}

.medical-documents {
  padding-bottom: 16px;
  margin-bottom: 16px;
  h4 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.125rem;
  }
  .list-of-documents {
    display: flex;
    flex-wrap: wrap;

    .single-document {
      width: 110px !important;
      height: 110px;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #dddddd;
      cursor: pointer;

      img {
        width: 110px;
        height: 110px;
        object-fit: cover;
      }
      a {
        color: variables.$secondary;
        text-decoration: none;
      }

      a:hover {
        color: #00a0c6;
        text-decoration: none;
      }
      .file {
        width: 110px;
        height: 110px;
        display: table;

        .file-contents {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          padding: 2px;

          p {
            word-break: break-all;
            font-size: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
}
