@use "../scss/variables.module.scss";

.updates-tab {
  .load-more-updates {
    text-align: center;
  }
  .noupdates {
    text-align: center;
  }

  .single-update {
    margin-bottom: 46px;
    padding: 20px;
    border: 1px solid variables.$semiWhite;
    .update-header {
      position: relative;
      margin-bottom: 20px;

      .creator-deatils {
        p {
          font-size: 16px;
          color: variables.$primaryDark;
          line-height: 16px;
          font-weight: 700;

          span {
            font-size: 14px;
            color: variables.$tertiaryDark;
            font-weight: 600;
          }
        }
      }

      .serial-number {
        position: absolute;
        right: -40px;
        top: 0;
        width: 40px;
        height: 40px;
        background-color: variables.$primary;
        p {
          color: variables.$white;
        }
      }
    }

    .update-content {
      .update-title {
        border-bottom: 1px solid variables.$headingBorder;
        padding-bottom: 12px;
        margin-bottom: 18px;
        overflow-wrap: anywhere;
      }

      .update-gallery {
        margin-top: 16px;
        display: flex;
        margin-right: 10px;
        flex-wrap: wrap;

        .updates-gallery-video-container {
          position: relative;
          .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
          }
        }

        .single-media {
          margin-right: 6px;
          cursor: pointer;

          img {
            width: 110px;
            height: 109px;
            object-fit: fill;
          }
        }
      }
    }
  }

  .ask {
    border-radius: 4px;
    background-color: variables.$tertiaryGrey;
    padding: 20px;

    p {
      color: variables.$black;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .project-updates {
    h3 {
      padding-bottom: 8px;
      border-bottom: 1px solid variables.$black;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .updates-tab {
    padding: 0px 0rem;
  }
  .update-header {
    .serial-number {
      right: -20px !important;
    }
  }
}
