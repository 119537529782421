@use "../../assets/scss/variables.module.scss";

.gallery {
  margin: 0px;
  .gallery-showcase {
    margin-bottom: 10px;
    height: 380px;
    position: relative;

    .verified-tag-container {
      position: absolute;
    }
    .verified-tag {
      background-color: variables.$secondary;
      height: 28px;
      max-width: 187px;
      width: 100%;
      position: absolute;
      top: 10px;
      left: calc(100% - 175px);
      display: flex;
      align-items: center;
      justify-content: center;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 8% 50%, 0 0);
      p {
        font-size: 13px;
        color: #ffffff;
        font-weight: 600;
        text-transform: uppercase;
        padding-left: 10px;
      }
    }
    .verified-tag-cut {
      background-color: variables.$secondary;
      height: 6px;
      width: 6px;
      position: absolute;
      right: -6px;
      top: 38px;

      &:after {
        position: absolute;
        left: 0;
        content: "";
        border-bottom: 4px solid white;
        border-left: 4px solid variables.$secondary;
        border-top: 8px solid variables.$secondary;
        border-right: 8px solid white;
      }
    }
  }
  .gallery-image {
    object-fit: cover;
    width: 100%;
    height: 380px;
  }
  .gallery-thumbnails {
    display: flex;
    flex-wrap: wrap;
    .thumbnail-container {
      margin-right: 10px;
      cursor: pointer;
      img {
        height: 40px;
        width: 45px;
        object-fit: cover;
      }
    }

    .gallery-video-container {
      position: relative;
      .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }
  }
}

